import { BusinessFieldsType } from 'app/api/types/business';
import {
    FuzzySearchOrgsReceivedData,
    OrgCategoriesResponse,
    OrgCountriesResponse,
    OrgGroupConfig,
    OrgLanguagesRequest,
    OrgLanguagesResponse,
    SearchOrgParams,
    SidebarPages,
    V2OrgData,
} from 'app/api/types/org';
import { SsoInfoResponse } from 'app/api/types/sso';

import ApiResourceBaseClass from './baseApiCalls';

import { generateQueryParamsString } from './utils';
import { SuccessResponse } from '../../types';

const RESOURCE_URI = 'org';

class Organization extends ApiResourceBaseClass {
    static getBusinessEditFields(orgId: number): Promise<BusinessFieldsType> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/business_fields`).then(
            ({ data }) => data,
        );
    }

    static updateBusinessEditFields(
        orgId: number,
        businessFields: BusinessFieldsType,
    ): Promise<SuccessResponse> {
        return Organization.post(`${RESOURCE_URI}/${orgId}/business_fields`, businessFields).then(
            ({ data }) => data,
        );
    }

    static getOrg(orgId: number): Promise<V2OrgData> {
        return Organization.get(`${RESOURCE_URI}/${orgId}`).then(({ data }) => data);
    }

    static searchOrgs(queryParams: SearchOrgParams): Promise<FuzzySearchOrgsReceivedData> {
        return Organization.get(
            `${RESOURCE_URI}/search?${generateQueryParamsString(queryParams)}`,
        ).then(({ data }) => data);
    }

    static getSsoInfo(orgId: number): Promise<SsoInfoResponse> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/sso_info`).then(({ data }) => data);
    }

    static getBusinessesCategory(orgId: number, lang?: string): Promise<OrgCategoriesResponse> {
        const params = {
            lang,
        };
        return Organization.get(`${RESOURCE_URI}/${orgId}/categories`, params).then(
            ({ data }) => data,
        );
    }

    static getBusinessesCountries(orgId: number): Promise<OrgCountriesResponse> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/countries`).then(({ data }) => data);
    }

    static getBusinessesLanguages(
        orgId: number,
        params: OrgLanguagesRequest = {},
    ): Promise<OrgLanguagesResponse> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/languages`, params).then(
            ({ data }) => data,
        );
    }

    static getGroupsConfig(orgId: string | number): Promise<OrgGroupConfig> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/groups_config`).then(({ data }) => data);
    }

    static getSidebarPages(orgId: number): Promise<SidebarPages> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/sidebar_pages`).then(({ data }) => data);
    }
}

export default Organization;
