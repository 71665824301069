import { useEffect, useState } from 'react';

import { Button, Text, TextInput } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import EditFormDescription from 'app/businessEditV2/components/EditFormDescription';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import { CompetitorWithId } from 'admin/common/api/types/competitiveBenchmark';

import { Footer, HeaderText, LeftSideContent } from './EditCompetitorModal.styled';
import { useCreateNewCompetitor } from '../hooks/useCreateNewCompetitor';
import { useUpdateCompetitor } from '../hooks/useUpdateCompetitor';

type EditCompetitorModalProps = {
    onClose: () => void;
    opened: boolean;
    competitor?: CompetitorWithId;
    keywordId: string;
};

type Props = {
    onSave: () => void;
    competitor?: CompetitorWithId;
    keywordId: string;
};

const EditCompetitorContent = ({ competitor, keywordId, onSave }: Props) => {
    const { t } = useTranslation();
    const [isNameErrorNotice, setIsNameErrorNotice] = useState<string | undefined>(undefined);
    const {
        mutate: updateCompetitor,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError,
        reset: resetUpdate,
    } = useUpdateCompetitor();
    const {
        mutate: createCompetitor,
        isSuccess: isCreateSuccess,
        isError: isCreateError,
        reset: resetCreate,
    } = useCreateNewCompetitor();

    const [name, setName] = useState(competitor?.name || '');
    const [included, setIncluded] = useState(competitor?.included || []);
    const [excluded, setExcluded] = useState(competitor?.excluded || []);

    useEffect(() => {
        if (isUpdateSuccess) {
            onSave();
            resetUpdate();
        }
        setIsNameErrorNotice(undefined);
    }, [isUpdateSuccess]);

    useEffect(() => {
        if (isCreateSuccess) {
            onSave();
            resetCreate();
        }
        setIsNameErrorNotice(undefined);
    }, [isCreateSuccess]);

    useEffect(() => {
        if (isUpdateError || isCreateError) {
            setIsNameErrorNotice(
                t('admin:competitive_benchmark__modal_create_competitor_error_notice'),
            );
        }
    }, [isUpdateError, isCreateError]);

    useEffect(() => {
        setIsNameErrorNotice(undefined);
    }, [name]);

    const handleSubmit = () => {
        const updatedCompetitor = { ...competitor, name, included, excluded };
        if (updatedCompetitor.id !== undefined) {
            updateCompetitor({ competitor: updatedCompetitor as CompetitorWithId });
        } else {
            createCompetitor({ ...updatedCompetitor, keywordId });
        }
    };

    return (
        <>
            <LeftSideContent>
                <HeaderText as="span" variant="heading2">
                    {competitor?.name}
                </HeaderText>
                <TextInput
                    label={t('admin:competitor_name')}
                    dataTrackId={t('admin:competitor_name')}
                    value={name}
                    onChange={value => setName(value)}
                    required
                    notice={isNameErrorNotice}
                    error={!!isNameErrorNotice}
                />
                <Text as="span" variant="heading6">
                    {t('admin:edit_competitor_modal_subtitle')}
                </Text>
                <TextInput
                    dataTrackId={t('admin:edit_competitor_modal_include')}
                    label={t('admin:edit_competitor_modal_include')}
                    value={included}
                    onChange={value =>
                        setIncluded(
                            value
                                .split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                        )
                    }
                />
                <TextInput
                    dataTrackId={t('admin:edit_competitor_modal_exclude')}
                    label={t('admin:edit_competitor_modal_exclude')}
                    value={excluded}
                    onChange={value =>
                        setExcluded(
                            value
                                .split(',')
                                .map(item => item.trim())
                                .filter(item => item !== ''),
                        )
                    }
                />
            </LeftSideContent>
            <Footer>
                <Button
                    full
                    disabled={!name}
                    isLoading={false}
                    dataTrackId={''}
                    onClick={handleSubmit}
                >
                    {t('save')}
                </Button>
            </Footer>
        </>
    );
};

export const EditCompetitorModal = ({
    onClose,
    opened,
    competitor,
    keywordId,
}: EditCompetitorModalProps) => {
    const { t } = useTranslation();
    const childrenRight = (
        <EditFormDescription
            title={t('admin:edit_competitor_modal_help_title')}
            description={t('admin:edit_competitor_modal_help_description')}
            advice={t('admin:edit_competitor_modal_help_advice')}
        />
    );

    return (
        <FullScreenModal
            onHideClick={onClose}
            show={opened}
            childrenRight={childrenRight}
            childrenLeft={
                <EditCompetitorContent
                    competitor={competitor}
                    keywordId={keywordId}
                    onSave={onClose}
                />
            }
        />
    );
};
