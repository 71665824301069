import { Button, Dialog, Modal, Stack } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

interface ConfirmModalWithTextProps {
    title: string;
    subTitle: string;
    opened: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

export const ConfirmWarningModal = ({
    opened,
    title,
    subTitle,
    onConfirm,
    onClose,
}: ConfirmModalWithTextProps) => {
    const { t } = useTranslation();

    const confirmAndCloseModal = () => {
        onConfirm();
        onClose();
    };

    return (
        <Modal isOpen={opened} closeModal={onClose}>
            <Dialog dataTrackId="" subTitle={subTitle} title={title}>
                <Stack direction="row">
                    <Button
                        dataTrackId=""
                        variant="secondary"
                        shape="cube"
                        size="large"
                        appearance="outlined"
                        full
                        onClick={onClose}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        dataTrackId=""
                        variant="danger"
                        shape="cube"
                        size="large"
                        full
                        onClick={confirmAndCloseModal}
                    >
                        {t('confirm')}
                    </Button>
                </Stack>
            </Dialog>
        </Modal>
    );
};
