import React, { FC, useCallback, useState } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import { Filters } from '@partoohub/ui';
import { Button, Dialog, Modal, Option, Stack, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DiffusionCleanerAdminApiCall } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DiffusionCleanerExportFilters } from 'admin/common/api/types/diffusion_cleaner';
import { languagesData } from 'admin/common/data/languages';

import { useNewGroups } from 'admin/common/hooks/queries/useNewGroups';

import { getSelectedOptions } from 'admin/common/utils/selectOptions';

type Props = {
    openedWarningModal: boolean;
    setOpenedWarningModal: (openedWarningModal: boolean) => void;
    filters: DiffusionCleanerExportFilters;
};

export const DiffusionCleanerLangSelect: FC<Props> = ({
    openedWarningModal,
    setOpenedWarningModal,
    filters,
}: Props) => {
    const { t } = useTranslation();
    const langOptions: Option[] = languagesData
        .filter(({ supportedForDemo }) => supportedForDemo)
        .map(({ code }) => ({
            label: t(`original-${code}`),
            name: code,
            value: code,
        }));
    const section = [{ options: langOptions }];

    const [langs, setLangs] = useState<Record<string, boolean>>({});
    const lang = Object.entries(langs)
        .filter(([_, selected]) => !!selected)
        .map(([value, _]) => value)[0];
    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Download,
    };

    const orgId = filters.org?.value;
    const enableNewGroups = useNewGroups(orgId ? parseInt(orgId) : undefined) || false;
    const resetModal = () => {
        setOpenedWarningModal(false);
        setLangs({});
    };
    const exportDiffusionErrorsMutation = useCallback(
        async (
            filters: DiffusionCleanerExportFilters,
            lang: string | undefined,
            enableNewGroups: boolean,
        ) => {
            try {
                const queryParams = {
                    provider: filters.provider?.value,
                    orgId: filters.org?.value,
                    publisher: getSelectedOptions(filters.publishers),
                    errorKeys: getSelectedOptions(filters.errorTypes),
                    lang: lang ? lang : 'en',
                    ...(enableNewGroups
                        ? { groups: getSelectedOptions(filters.groups) }
                        : { group__in: getSelectedOptions(filters.groups) }),
                };
                await DiffusionCleanerAdminApiCall.diffusionCleanerExport(queryParams);
                toast.success(
                    t('admin:page_exporter__success_toast__content'),
                    t('admin:page_exporter__success_toast__title'),
                );
            } catch (error: any) {
                toast.error(error.message, t('admin:toast_error__title'));
            } finally {
                resetModal();
            }
        },
        [],
    );

    return (
        <Modal isOpen={openedWarningModal} closeModal={() => resetModal()}>
            <div style={{ width: '500px' }}>
                <Dialog
                    dataTrackId=""
                    header={
                        <div style={{ paddingTop: '24px' }}>
                            <Illustration
                                dataTrackId="admin_diffusion_cleaner_download"
                                illustration={illustrationElement}
                                width="320"
                                height="140"
                            />
                        </div>
                    }
                    title={t('admin:page_diffusion_cleaner__download_title')}
                    subTitle={t('admin:page_diffusion_cleaner__download_subtitle')}
                >
                    <Filters
                        dataTrackId="admin_diffusion_cleaner__select_language"
                        menuListArray={section}
                        selectedItems={langs}
                        onChange={setLangs}
                        placeholder={t('admin:page_demo_creator__select_language')}
                        position="bottom"
                        maxHeight={300}
                        required
                    />
                    <Stack direction="row">
                        <Button
                            dataTrackId="admin_diffusion_cleaner_cancel_download"
                            variant="secondary"
                            size="large"
                            onClick={() => setOpenedWarningModal(false)}
                            appearance="outlined"
                            full
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            dataTrackId="admin_diffusion_cleaner_menu_download"
                            variant="primary"
                            size="large"
                            onClick={() =>
                                exportDiffusionErrorsMutation(filters, lang, enableNewGroups)
                            }
                            full
                            disabled={lang == undefined}
                        >
                            {t('download')}
                        </Button>
                    </Stack>
                </Dialog>
            </div>
        </Modal>
    );
};
