import { FC, useCallback, useEffect, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { FontAwesomeIconsPartooUsed, IconPrefix, Option, Skeleton, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DiffusionCleanerCountData } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DiffusionCleanerCountFilters } from 'admin/common/api/types/diffusion_cleaner';

import { DeprecatedGroupSelect } from 'admin/common/components/inputs/DeprecatedGroupSelect';
import { LargeFilter } from 'admin/common/components/inputs/NewSelect.styled';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { NewProviderSelect } from 'admin/common/components/inputs/ProviderSelect/NewProviderSelect';
import AdminPage from 'admin/common/components/templates/AdminPage';
import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';
import { STATIC_BASE_URL } from 'admin/common/data/config';
import { useNewGroups } from 'admin/common/hooks/queries/useNewGroups';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';
import { EmptyPlaceholder } from 'admin/content/logs/common/components/List.styled';

import {
    DiffusionCleanerFiltersWrapper,
    DiffusionCleanerSmallFilter,
} from './components/DiffusionCleaner.styled';
import { DiffusionCleanerExportButton } from './components/DiffusionCleanerExportButton';
import { DiffusionCleanerLangSelect } from './components/DiffusionCleanerLangSelect';
import { DiffusionCleanerPublisherSelect } from './components/DiffusionCleanerPublisherSelect';
import { DiffusionErrorTypeSelect } from './components/DiffusionErrorTypeSelect';
import { DiffusionCleanerColumnHeadings } from './components/DiffusionSortColumn/DiffusionCleanerColumnHeadings';
import { ErrorCard } from './components/ErrorCard/ErrorCard';
import {
    DiffusionCleanerStartPageContainer,
    DiffusionCleanerStartPageContent,
    DiffusionErrorList,
} from './DiffusionCleaner.styled';
import GroupSelect from './GroupSelect';
import { useDiffusionCount } from './hooks/useDiffusionCount';
import { useOrg } from './hooks/useOrg';

export const DiffusionCleaner: FC = () => {
    const { t } = useTranslation();
    const [org, setOrg] = useOrg();

    const initialFilters: DiffusionCleanerCountFilters = {
        provider: undefined,
        org: org,
        groups: {},
        publishers: undefined,
        errorTypes: undefined,
    };
    const [filters, setFilters] = useState(initialFilters);
    const [diffusionErrors, setDiffusionErrors] = useState<DiffusionCleanerCountData[]>([]);
    const [openedWarningModal, setOpenedWarningModal] = useState<boolean>(false);

    const orgId = org?.value;
    const enableNewGroups = useNewGroups(orgId ? parseInt(orgId) : undefined);

    const { data, isLoading, pageHasError } = useDiffusionCount(filters, enableNewGroups);
    const arePrimaryFiltersSelected = org !== undefined || !!filters.provider;

    const resetFilters = useCallback((new_provider: Option | undefined = undefined) => {
        setFilters(prev => ({
            ...prev,
            provider: new_provider,
            org: undefined,
            groups: {},
            publishers: {},
            errorKeys: {},
        }));
        setDiffusionErrors([]);
        setOrg(undefined);
    }, []);

    const handleOrgChange = useCallback((selectedOrg?: Option) => {
        setOrg(selectedOrg);
    }, []);
    const handlePublishersChange = useCallback(
        (publishers: Record<string, boolean> | undefined = undefined) => {
            setFilters(prev => ({ ...prev, publishers: publishers }));
        },
        [],
    );
    const handleErrorTypesChange = useCallback(
        (errorTypes: Record<string, boolean> | undefined = undefined) => {
            setFilters(prev => ({ ...prev, errorTypes: errorTypes }));
        },
        [],
    );
    const handleGroupsChange = useCallback((groups: Record<string, boolean>) => {
        setFilters(prev => ({ ...prev, groups: groups }));
    }, []);

    useEffect(() => {
        if (!isLoading) {
            setDiffusionErrors(data || []);
        }
    }, [data]);

    useEffect(() => {
        setFilters(prev => ({ ...prev, groups: {}, org: org }));
    }, [org]);

    const illustrationElementSuccess: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.VeryHappySuccess,
        type: IllustrationCategoryEnum.Laptop,
    };
    const illustrationElementError: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Sad,
        type: IllustrationCategoryEnum.Laptop,
    };

    function renderContent() {
        if (isLoading) return <Skeleton col={2} />;
        if (!arePrimaryFiltersSelected) return renderStartContent();
        if (pageHasError) return renderPageInError();
        if (diffusionErrors.length) return renderErrorCards();
        return renderNotFound();
    }

    function renderErrorCards() {
        return (
            <DiffusionErrorList role="list">
                {diffusionErrors.map(diffusionError => (
                    <ErrorCard
                        errorName={diffusionError.error_name}
                        count={diffusionError.business_count}
                        key={diffusionError.publisher + diffusionError.error_key}
                        publisher={diffusionError.publisher}
                        description={diffusionError.description}
                        wikiLink={diffusionError.wiki_link}
                        bulkButtons={diffusionError.bulk_buttons}
                        businessButtons={diffusionError.business_buttons}
                        provider={filters.provider?.value}
                        orgId={orgId}
                        groups={getSelectedOptions(filters.groups)}
                        errorType={diffusionError.error_key}
                        businessEditSection={diffusionError.business_edit_section}
                    />
                ))}
            </DiffusionErrorList>
        );
    }

    function renderStartContent() {
        return (
            <DiffusionCleanerStartPageContainer>
                <DiffusionCleanerStartPageContent>
                    <img
                        src={`${STATIC_BASE_URL}/images/app/diffusion_cleaner_start_page.svg`}
                        alt="no-result-found"
                    />
                    <Text as="span" variant="heading3">
                        {t('admin:page_diffusion_cleaner__start_page__title')}
                    </Text>
                </DiffusionCleanerStartPageContent>
            </DiffusionCleanerStartPageContainer>
        );
    }

    function renderPageInError() {
        return (
            <EmptyPlaceholder>
                <NotFoundTemplate
                    show
                    title={t('admin:page_diffusion_cleaner__error_placeholder__title')}
                    imgSrc={illustrationElementError}
                    buttonText={t('admin:page_diffusion_cleaner__error_placeholder__button')}
                    handleClick={() => window.location.reload()}
                    icon={[FontAwesomeIconsPartooUsed.faRotate, IconPrefix.SOLID]}
                />
            </EmptyPlaceholder>
        );
    }

    function renderNotFound() {
        return (
            <EmptyPlaceholder>
                <NotFoundTemplate
                    show
                    title={t('admin:page_diffusion_cleaner__empty_placeholder__title')}
                    imgSrc={illustrationElementSuccess}
                    withButton={false}
                />
            </EmptyPlaceholder>
        );
    }

    return (
        <AdminPage
            dataTrackId="page_diffusion_cleaner"
            title={t('admin:page_diffusion_cleaner__title')}
            buttons={
                arePrimaryFiltersSelected &&
                diffusionErrors.length !== 0 && (
                    <DiffusionCleanerExportButton onClick={() => setOpenedWarningModal(true)} />
                )
            }
        >
            <DiffusionCleanerFiltersWrapper>
                <LargeFilter
                    data-track="admin_diffusion_cleaner__filter_provider"
                    data-intercom-target="admin_diffusion_cleaner__filter_provider"
                >
                    <NewProviderSelect
                        dataTrackId="admin_diffusion_cleaner__filter_provider"
                        provider={filters.provider}
                        setProvider={resetFilters}
                    />
                </LargeFilter>
                <LargeFilter
                    data-track="admin_diffusion_cleaner__filter_organization"
                    data-intercom-target="admin_diffusion_cleaner__filter_organization"
                >
                    <NewOrganizationSelect
                        orgs={org}
                        setOrgs={handleOrgChange}
                        queryParams={{
                            provider: filters.provider?.value,
                        }}
                        dataTrackId="admin_diffusion_cleaner__filter_organization"
                        placeholder={t('admin:organization')}
                    />
                </LargeFilter>
                <LargeFilter
                    data-track="admin_diffusion_cleaner__filter_group"
                    data-intercom-target="admin_diffusion_cleaner__filter_group"
                >
                    {enableNewGroups ? (
                        <GroupSelect
                            value={filters.groups}
                            onChange={groups =>
                                handleGroupsChange(
                                    groups.reduce(
                                        (obj, value) => ({
                                            ...obj,
                                            [value]: true,
                                        }),
                                        {},
                                    ),
                                )
                            }
                            orgId={orgId ? parseInt(orgId) : undefined}
                        />
                    ) : (
                        <DeprecatedGroupSelect
                            dataTrackId="admin_diffusion_cleaner__filter_group"
                            groups={filters.groups}
                            setGroups={handleGroupsChange}
                            disabled={org === undefined}
                            orgId={orgId}
                        />
                    )}
                </LargeFilter>
                <DiffusionCleanerSmallFilter>
                    <DiffusionCleanerPublisherSelect
                        publishers={filters.publishers}
                        setPublishers={handlePublishersChange}
                        disabled={!arePrimaryFiltersSelected}
                    />
                </DiffusionCleanerSmallFilter>
                <DiffusionCleanerSmallFilter>
                    <DiffusionErrorTypeSelect
                        filters={filters}
                        setErrorTypes={handleErrorTypesChange}
                        disabled={!arePrimaryFiltersSelected}
                    />
                </DiffusionCleanerSmallFilter>
            </DiffusionCleanerFiltersWrapper>
            <DiffusionCleanerColumnHeadings />
            {renderContent()}
            <DiffusionCleanerLangSelect
                openedWarningModal={openedWarningModal}
                setOpenedWarningModal={setOpenedWarningModal}
                filters={filters}
            />
        </AdminPage>
    );
};
