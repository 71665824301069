import React, { useState } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Link, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { Publisher } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DiffusionCleanerStatusFilters } from 'admin/common/api/types/diffusion_cleaner';
import { STATIC_BASE_URL } from 'admin/common/data/config';

import {
    Actions,
    ChevronWrapper,
    Count,
    ErrorInfo,
    IconWrapper,
} from 'admin/content/tools/pages/DiffusionCleaner/components/common/DiffusionCleanerCommon.styled';

import { BusinessListStyled } from './BusinessList.styled';
import { BulkCopyButton, BulkPushButton } from './buttons';
import { QuickConnect } from './buttons/QuickConnect';

import {
    BulkButtonWrapper,
    BusinessName,
    DisclosureTrigger,
    PaginationLayout,
    QuickConnectAction,
    RowWithLayout,
    StyledChevron,
} from './ErrorCard.styled';

export const BUSINESSES_PER_ERROR_PAGE = 5;
const publisherIconSvgName: { [key in Publisher]?: string } = {
    google_my_business: 'google_white_background_without_margins',
    facebook: 'facebook-white-background-without-margins',
    apple_business_connect: 'apple',
};

type Props = {
    buttons: string[];
    provider: string | undefined;
    orgId: string | undefined;
    groups: string[];
    errorType: string;
    publisher: Publisher;
};

const Buttons = ({ buttons, provider, orgId, publisher, errorType, groups }: Props) => {
    const { t } = useTranslation();
    return (
        <BulkButtonWrapper>
            {buttons.map(button => {
                switch (button) {
                    case 'copy_business_info':
                        return (
                            <BulkCopyButton
                                key={`diffusion-cleaner-bulk-copy-button-${button}-${errorType}`}
                                provider={provider}
                                orgId={orgId}
                                publisher={publisher}
                                errorType={errorType}
                                groups={groups}
                            />
                        );
                    case 'quick_connect':
                        if (publisher === 'google_my_business' || publisher === 'facebook') {
                            const hasExtension = window.partoo_extension;
                            return (
                                <QuickConnectAction
                                    key={`diffusion-cleaner-bulk-quick-connect-div-${button}-${errorType}`}
                                >
                                    {!hasExtension && (
                                        <Text
                                            variant="bodyMBold"
                                            as="span"
                                            color={HEX_COLORS.warning}
                                            className="margin_right--triple"
                                        >
                                            <Link
                                                key={`diffusion-cleaner-bulk-quick-connect-text-${button}-${errorType}`}
                                                href="https://chrome.google.com/webstore/detail/partoo-extension/mbpokkkmolldhgnmdojcggoanoajldhb"
                                                target="_blank"
                                                variant="warning"
                                            >
                                                {t(
                                                    'admin:page_diffusion_cleaner__no_extension_text',
                                                )}
                                            </Link>
                                        </Text>
                                    )}
                                    <QuickConnect
                                        key={`diffusion-cleaner-bulk-quick-connect-button-${button}-${errorType}`}
                                        provider={provider}
                                        orgId={orgId}
                                        publisher={publisher}
                                    />
                                </QuickConnectAction>
                            );
                        } else return;

                    case 'push':
                        return (
                            <BulkPushButton
                                key={`diffusion-cleaner-bulk-push-button-${button}-${errorType}`}
                                provider={provider}
                                orgId={orgId}
                                publisher={publisher}
                                errorKey={errorType}
                                groups={groups}
                            />
                        );
                }
            })}
        </BulkButtonWrapper>
    );
};

export const ErrorCard = ({
    errorName,
    count,
    publisher,
    description,
    bulkButtons,
    businessButtons,
    provider,
    orgId,
    groups,
    errorType,
    businessEditSection,
}: {
    errorName: string;
    count: number;
    publisher: Publisher;
    description: string;
    wikiLink: string;
    bulkButtons: string[];
    businessButtons: string[];
    provider: string | undefined;
    orgId: string | undefined;
    groups: string[];
    errorType: string;
    businessEditSection: string;
}) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [page, setPage] = useState(1);

    const toggleExpanded = () => setExpanded(!expanded);

    const pageCount = Math.ceil(count / BUSINESSES_PER_ERROR_PAGE);

    const filters: DiffusionCleanerStatusFilters = {
        provider: provider,
        org: orgId,
        publisher: publisher,
        errorKey: errorType,
        page: page,
    };

    return (
        <RowWithLayout role="listitem">
            <DisclosureTrigger expanded={expanded} onClick={toggleExpanded}>
                <IconWrapper>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/${publisherIconSvgName[publisher] ?? publisher}.svg`}
                    />
                </IconWrapper>
                <ErrorInfo>
                    <BusinessName>
                        {t(errorName)}
                        {/* <LinkButton infoLink={wikiLink} /> */}
                    </BusinessName>
                    <Text variant="bodyMRegular" color={HEX_COLORS.secondary}>
                        {t(description)}
                    </Text>
                </ErrorInfo>
                <Count variant="bodyMBold">{count}</Count>
                <Actions>
                    <Buttons
                        buttons={bulkButtons}
                        provider={provider}
                        orgId={orgId}
                        publisher={publisher}
                        groups={groups}
                        errorType={errorType}
                    />
                </Actions>
                <ChevronWrapper>
                    <StyledChevron upward={expanded} />
                </ChevronWrapper>
            </DisclosureTrigger>
            {expanded && (
                <div>
                    <BusinessListStyled
                        businessButtons={businessButtons}
                        filters={filters}
                        businessEditSection={businessEditSection}
                    />
                    {pageCount > 1 && (
                        <PaginationLayout
                            dataTrackId={`pagination${errorName}`}
                            onChange={setPage}
                            page={page}
                            pageCount={pageCount}
                        />
                    )}
                </div>
            )}
        </RowWithLayout>
    );
};
