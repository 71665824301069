import { FC, useEffect, useState } from 'react';

import {
    Chip,
    CustomFile,
    DropzoneTypeEnum,
    FontAwesomeIconsPartooUsed,
    Icon,
    IconPrefix,
    Stack,
    toast,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useMutation } from 'react-query';

import MessagingCallDeflectionApiCalls from 'app/api/v2/api_calls/messagingCallDeflectionApiCalls';

import { EllipsisLink } from 'admin/common/components/buttons/EllipsisLink';
import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

import { UrlWrapper } from 'admin/content/connectAs/pages/ConnectAs/ConnectAs.styled';

import { AUDIO_TYPES } from './AllowedAudioTypes';

const CallDeflectionAudioUpload: FC = () => {
    const { t } = useTranslation();
    const [audioFiles, setAudioFiles] = useState<CustomFile[]>([]);
    const [audioFileUrl, setAudioFileUrl] = useState<string>('');
    const { mutateAsync } = useMutation(MessagingCallDeflectionApiCalls.uploadAudio);

    const uploadAudioFileOnClick = async () => {
        if (audioFiles?.length) {
            const data = await mutateAsync(audioFiles[0]);
            setAudioFileUrl(data?.url);
            setAudioFiles([]);
            if (data?.url) {
                toast.success(
                    t('admin:page__call_deflection_audio_upload__success_toast__content'),
                    t('admin:page__call_deflection_audio_upload__success_toast__title'),
                );
            } else {
                toast.error(
                    t('admin:page__call_deflection_audio_upload__error_toast__content'),
                    t('admin:page__call_deflection_audio_upload__error_toast__title'),
                );
            }
        }
    };

    const onChipClick = () => {
        if (audioFileUrl) {
            navigator.clipboard.writeText(audioFileUrl);
            toast.success(
                t('admin:page__call_deflection_audio_upload__copy__success_toast__content'),
                t('admin:page__call_deflection_audio_upload__copy__success_toast__title'),
            );
        }
    };

    const copyChip = (
        <div
            data-track={'admin__call_deflection_audio_upload'}
            data-intercom-targert={'admin__call_deflection_audio_upload'}
        >
            <Chip
                dataTrackId="admin__call_deflection_audio_upload__chip"
                disabled={!audioFileUrl}
                iconAction={<i className="fas fa-copy" />}
                onClick={onChipClick}
            >
                <Stack gap="4px" direction="row" alignItems="center">
                    <Icon
                        icon={[FontAwesomeIconsPartooUsed.faCopy, IconPrefix.SOLID]}
                        iconSize="12px"
                        color="primary"
                    />
                    {t('admin:copy')}
                </Stack>
            </Chip>
        </div>
    );

    useEffect(() => {
        if (audioFiles.length) {
            setAudioFileUrl('');
        }
    }, [audioFiles]);

    return (
        <AdminPage
            dataTrackId="admin__call_deflection"
            title={t('admin:page__call_deflection_audio_upload__title')}
            description={t('admin:page__call_deflection_audio_upload__description')}
        >
            <BorderedContainer>
                <PageFormRow marginBottom={1}>
                    <DropZoneWithoutUpload
                        title={t('drop_zone_label')}
                        acceptedDescription={
                            <DropZoneDescription acceptedExtensions={AUDIO_TYPES} />
                        }
                        type={DropzoneTypeEnum.Document}
                        files={audioFiles}
                        setFiles={setAudioFiles}
                        multiple={false}
                        disabled={audioFiles.length === 1}
                        full
                        accept={AUDIO_TYPES}
                    />
                </PageFormRow>
                <PageFormRow>
                    <SubmitButton
                        dataTrackId="admin__call_deflection__audio_upload"
                        onClick={uploadAudioFileOnClick}
                        disabled={!audioFiles.length}
                        text={t('admin:page__call_deflection__submit_button')}
                    />
                </PageFormRow>
                <PageFormRow>
                    <UrlWrapper>
                        <EllipsisLink value={audioFileUrl} icon={copyChip} />
                    </UrlWrapper>
                </PageFormRow>
            </BorderedContainer>
        </AdminPage>
    );
};

export default CallDeflectionAudioUpload;
