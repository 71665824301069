import { makeDbAdminUrl } from 'admin/common/data/dbAdminHelpers';

import {
    CATEGORIES_PATH,
    COMPETITIVE_BENCHMARK_PATH,
    CONNECT_AS_PATH,
    DAILY_REPORTS_PATH,
    DEMO_CREATOR_PATH,
    DIFFUSION_CLEANER_PATH,
    DIFFUSION_LOGS_PATH,
    DUPLICATES_CLIENT_LOCATION_SEARCH_TOOL_PATH,
    EXPORTER_PATH,
    GROUPS_MIGRATION_PATH,
    IMPORTER_PATH,
    IMPORT_LOGS_PATH,
    MENU_CREATION_PATH,
    MESSAGES_EXPORTER_PATH,
    MESSAGES_IMPORTER_PATH,
    OLD_EXPORTER_PATH,
    OLD_IMPORTER_PATH,
    OVERRIDING_TOOL_PATH,
    PROSPECTS_LOCATION_SEARCH_TOOL_PATH,
    REVIEW_TOOL_PATH,
    SERVICE_CREATION_PATH,
    TRIPADVISOR_IMPORTER_PATH,
} from 'admin/common/data/routeIds';
import env from 'admin/common/utils/getEnvironment';

export enum LinkTypeEnum {
    Admin = 'ADMIN',
    DbAdmin = 'DBADMIN',
    App = 'APP',
}

export interface QuickActionLink {
    name: string;
    link: string;
    type: LinkTypeEnum;
}

const appHost = window.location.host.replace('admin.', 'app.');

const makeAppUrl = (path: string) => `https://${appHost}/${path}`;

export const QUICK_ACTION_LINKS: Array<QuickActionLink> = [
    // Direct app link
    {
        name: 'Application',
        link: makeAppUrl(''),
        type: LinkTypeEnum.App,
    },
    // Admin
    { name: 'Connect as', link: CONNECT_AS_PATH, type: LinkTypeEnum.Admin },
    { name: 'Exporter', link: EXPORTER_PATH, type: LinkTypeEnum.Admin },
    { name: 'Old exporter', link: OLD_EXPORTER_PATH, type: LinkTypeEnum.Admin },
    {
        name: 'Messages exporter',
        link: MESSAGES_EXPORTER_PATH,
        type: LinkTypeEnum.Admin,
    },
    { name: 'Importer', link: IMPORTER_PATH, type: LinkTypeEnum.Admin },
    { name: 'Old importer', link: OLD_IMPORTER_PATH, type: LinkTypeEnum.Admin },
    {
        name: 'Messages importer',
        link: MESSAGES_IMPORTER_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Tripadvisor importer',
        link: TRIPADVISOR_IMPORTER_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Service creation',
        link: SERVICE_CREATION_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Menu creation',
        link: MENU_CREATION_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Group Migration',
        link: GROUPS_MIGRATION_PATH,
        type: LinkTypeEnum.Admin,
    },
    { name: 'Categories', link: CATEGORIES_PATH, type: LinkTypeEnum.Admin },
    {
        name: 'Diffusion logs',
        link: DIFFUSION_LOGS_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Override states',
        link: OVERRIDING_TOOL_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Review treatment',
        link: REVIEW_TOOL_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Prospect listing',
        link: PROSPECTS_LOCATION_SEARCH_TOOL_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Duplicate business search',
        link: DUPLICATES_CLIENT_LOCATION_SEARCH_TOOL_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Daily reports',
        link: DAILY_REPORTS_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Import logs',
        link: IMPORT_LOGS_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Diffusion cleaner',
        link: DIFFUSION_CLEANER_PATH,
        type: LinkTypeEnum.Admin,
    },
    {
        name: 'Competitive Benchmark',
        link: COMPETITIVE_BENCHMARK_PATH,
        type: LinkTypeEnum.Admin,
    },
    // App
    {
        name: 'Locations',
        link: makeAppUrl('visibility/locations'),
        type: LinkTypeEnum.App,
    },
    {
        name: 'Listings',
        link: makeAppUrl('visibility/diffusion'),
        type: LinkTypeEnum.App,
    },
    {
        name: 'Reviews',
        link: makeAppUrl('customer-experience/reviews'),
        type: LinkTypeEnum.App,
    },
    {
        name: 'Send invitation',
        link: makeAppUrl('customer-experience/send-invite'),
        type: LinkTypeEnum.App,
    },
    {
        name: 'Configure connections',
        link: makeAppUrl('settings/configuration/connection'),
        type: LinkTypeEnum.App,
    },
    // DbAdmin
    { name: 'Users', link: makeDbAdminUrl('user'), type: LinkTypeEnum.DbAdmin },
    {
        name: 'Providers',
        link: makeDbAdminUrl('providersql'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Organizations',
        link: makeDbAdminUrl('organization'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Groups',
        link: makeDbAdminUrl('group'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Businesses',
        link: makeDbAdminUrl('business_sql'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Subscription Products',
        link: makeDbAdminUrl('productsubscription'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Business Users',
        link: makeDbAdminUrl('businesstousers'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Custom Fields',
        link: makeDbAdminUrl('customfieldsfororgpsql'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Categories',
        link: makeDbAdminUrl('categorysql'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Publishers OAuth Account',
        link: makeDbAdminUrl('publisheroauthaccount'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'GBM Accounts',
        link: makeDbAdminUrl('gmbaccount'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'GMB Locations',
        link: makeDbAdminUrl('gmblocationsql'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Facebook Pages',
        link: makeDbAdminUrl('facebookpage'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Publishers',
        link: makeDbAdminUrl('publisher'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Diffusers',
        link: makeDbAdminUrl('diffuser'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Diffusion Fields',
        link: makeDbAdminUrl('diffusionfield'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Publisher States',
        link: makeDbAdminUrl('publisherstate'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Diffusions',
        link: makeDbAdminUrl('diffusion'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Call logs',
        link: makeDbAdminUrl('calllog'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Puller Triggers',
        link: makeDbAdminUrl('pullertriggersql'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Reviews',
        link: makeDbAdminUrl('review'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Short Urls',
        link: makeDbAdminUrl('shorturl'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Attributes',
        link: makeDbAdminUrl('attribute'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Services',
        link: makeDbAdminUrl('service'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Google Messaging Locations',
        link: makeDbAdminUrl('googlemessaginglocation'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Google Messaging Agents',
        link: makeDbAdminUrl('googlemessagingagent'),
        type: LinkTypeEnum.DbAdmin,
    },
    { name: 'Post', link: makeDbAdminUrl('post'), type: LinkTypeEnum.DbAdmin },
    {
        name: 'Organization Webhooks',
        link: makeDbAdminUrl('organizationwebhook'),
        type: LinkTypeEnum.DbAdmin,
    },
    {
        name: 'Provider Webhooks',
        link: makeDbAdminUrl('providerwebhook'),
        type: LinkTypeEnum.DbAdmin,
    },
];

if (!env.isProd() && !env.isPreprod()) {
    QUICK_ACTION_LINKS.push({
        name: 'Demo creator',
        link: DEMO_CREATOR_PATH,
        type: LinkTypeEnum.Admin,
    });
}
