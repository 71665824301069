import React from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import {
    DiffusionCleanerAdminApiCall,
    Publisher,
} from 'admin/common/api/calls/diffusionCleanerApiCalls';

import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';
import { DIFFUSION_CLEANER_STATUS } from 'admin/common/data/queryKeysConstants';

import { useNewGroups } from 'admin/common/hooks/queries/useNewGroups';
import { EmptyPlaceholder } from 'admin/content/logs/common/components/List.styled';

import {
    SkeletonButton,
    SkeletonLine1,
    SkeletonLine2,
    SkeletonLine2Wrapper,
} from './Skeleton.styled';

import { BusinessCard } from '../BusinessCard/BusinessCard';
import { BusinessInfo, RoundedGreyBorderWithLayout } from '../BusinessCard/BusinessCard.styled';

const Skeleton = () => {
    return (
        <RoundedGreyBorderWithLayout>
            <BusinessInfo>
                <SkeletonLine1 />
                <SkeletonLine2Wrapper>
                    <SkeletonLine2 />
                </SkeletonLine2Wrapper>
            </BusinessInfo>
            <SkeletonButton />
        </RoundedGreyBorderWithLayout>
    );
};

export const BusinessList = ({
    provider,
    orgId,
    publisher,
    groups,
    errorType,
    businessButtons,
    count,
    className,
    page,
    businessEditSection,
}: {
    provider?: string;
    orgId?: string;
    publisher: Publisher;
    groups: string[];
    errorType: string;
    businessButtons: string[];
    count: number;
    className?: string;
    page: number;
    businessEditSection: string;
}) => {
    const { t } = useTranslation();
    const enableNewGroups = useNewGroups(orgId ? parseInt(orgId) : undefined);
    const {
        data: businesses,
        isLoading,
        isSuccess,
        isError,
        refetch,
    } = useQuery(
        [DIFFUSION_CLEANER_STATUS, orgId, provider, errorType, groups, publisher, page],
        () =>
            DiffusionCleanerAdminApiCall.diffusionCleanerStatus({
                orgId,
                provider,
                errorKey: errorType,
                publisher,
                page,
                ...(enableNewGroups ? { groups: groups } : { group__in: groups }),
            }),
        {
            staleTime: Infinity,
        },
    );

    const illustrationElement: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Sad,
        type: IllustrationCategoryEnum.Laptop,
    };

    return (
        <div className={className}>
            {isLoading && (
                <>
                    {new Array(count).fill(0).map((_, index) => (
                        <Skeleton key={`${orgId}-${errorType}-${index}`} />
                    ))}
                </>
            )}
            {isSuccess && (
                <>
                    {businesses.map(business => (
                        <BusinessCard
                            key={business.id + errorType}
                            business={business}
                            buttons={businessButtons}
                            provider={provider}
                            orgId={orgId}
                            publisher={publisher}
                            businessEditSection={businessEditSection}
                            callLogId={business.call_log_id}
                        />
                    ))}
                </>
            )}
            {isError && (
                <EmptyPlaceholder>
                    <NotFoundTemplate
                        show
                        title={t('admin:page_diffusion_cleaner__error_placeholder__title')}
                        imgSrc={illustrationElement}
                        buttonText={t(
                            'admin:page_diffusion_cleaner__business_list_error_placeholder__button',
                        )}
                        handleClick={refetch}
                        icon={[FontAwesomeIconsPartooUsed.faRotate, IconPrefix.SOLID]}
                    />
                </EmptyPlaceholder>
            )}
        </div>
    );
};
