import { useState } from 'react';

import { css } from '@emotion/react';
import { greyColorObject } from '@partoohub/branding';
import { Button, IconButton, Separator, Stack, Switch, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { KeywordAdminData } from 'admin/common/api/types/competitiveBenchmark';

import {
    ExpandableFullWidth,
    KeywordContentContainer,
    StackFullWidth,
} from './KeywordContent.styled';
import { CompetitorList } from '../CompetitorList';
import { EditCompetitorModal } from '../EditCompetitorModal';

type Props = {
    keyword: KeywordAdminData;
    toggleAction: (keywordName: string, active: boolean) => void;
};

export const KeywordContent = ({ keyword, toggleAction }: Props) => {
    const { t } = useTranslation();
    const [isExpand, setIsExpand] = useState(true);
    const [createCompetitorModalOpened, setCreateCompetitorModalOpened] = useState(false);

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        toggleAction(event.target.id, event.target.checked);
    };

    return (
        <>
            <KeywordContentContainer>
                <StackFullWidth direction="row" justifyContent="space-between">
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap="8px"
                        justifyContent="space-between"
                    >
                        <Switch
                            checked={keyword.active}
                            dataTrackId="keyword-section-toggle-keyword"
                            name="keyword"
                            onChange={handleSwitchChange}
                            id={keyword.keyword}
                        />
                        <Text
                            variant="heading6"
                            as="span"
                            css={css`
                                text-transform: capitalize;
                            `}
                        >
                            {keyword.keyword}
                        </Text>
                        <IconButton
                            dataTrackId="keyword-section-expand-container"
                            appearance="outlined"
                            icon={isExpand ? ['fa-chevron-down'] : ['fa-chevron-right']}
                            size="small"
                            onClick={() => setIsExpand(!isExpand)}
                        />
                    </Stack>
                    <></>
                    <Button
                        dataTrackId="button"
                        icon={['fa-add']}
                        onClick={() => setCreateCompetitorModalOpened(true)}
                        variant="primary"
                        appearance="text"
                        disabled={keyword.competitors.length >= 12}
                        tooltip={
                            keyword.competitors.length >= 12
                                ? t('admin:limit_competitors_text')
                                : undefined
                        }
                    >
                        {t('admin:add_competitor')}
                    </Button>
                </StackFullWidth>
                <Separator color={greyColorObject.initial} />
                <ExpandableFullWidth opened={isExpand}>
                    <CompetitorList keyword={keyword} />
                </ExpandableFullWidth>
            </KeywordContentContainer>
            <EditCompetitorModal
                onClose={() => setCreateCompetitorModalOpened(false)}
                opened={createCompetitorModalOpened}
                keywordId={keyword.id}
            />
        </>
    );
};
