import React, { useEffect, useState } from 'react';

import { Button, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import {
    DiffusionCleanerAdminApiCall,
    DiffusionCleanerStatusData,
} from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { useNewGroups } from 'admin/common/hooks/queries/useNewGroups';

type Props = {
    provider?: string;
    orgId?: string;
    publisher?: string;
    groups: string[];
    errorType: string;
};
export const BulkCopyButton: React.FC<Props> = ({
    provider,
    orgId,
    publisher,
    groups,
    errorType,
}) => {
    const { t } = useTranslation();

    const [data, setData] = useState<DiffusionCleanerStatusData[]>([]);
    useEffect(() => {
        setData([]);
    }, [provider, orgId, publisher, groups, errorType]);
    const enableNewGroups = useNewGroups(orgId ? parseInt(orgId) : undefined);

    const copyBusinessInfo = (businesses: Array<DiffusionCleanerStatusData>) => {
        const textToCopy = businesses.map(
            business =>
                '<p><b>' +
                business.name +
                '</b> - ' +
                business.address_full +
                ' - ' +
                business.zipcode +
                ' - ' +
                business.city +
                ' - ' +
                business.country,
        );
        const blobHtml = new Blob([textToCopy.toString()], {
            type: 'text/html',
        });
        const formattedText = [
            new ClipboardItem({
                ['text/html']: blobHtml,
            }),
        ];

        navigator.clipboard.write(formattedText);
        toast.success(t('admin:page_diffusion_cleaner__bulk_copy_button__success'), 'Success!');
    };

    const getBusinesses = useMutation(
        () =>
            DiffusionCleanerAdminApiCall.diffusionCleanerStatus({
                orgId,
                provider,
                errorKey: errorType,
                publisher,
                ...(enableNewGroups ? { groups: groups } : { group__in: groups }),
            }),
        {
            onSuccess: (data: DiffusionCleanerStatusData[]) => {
                setData(data);
                copyBusinessInfo(data);
            },
            onError: () => {
                toast.error(t('admin:page_diffusion_cleaner__bulk_copy_button__error'), 'Error!');
            },
        },
    );

    const onClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (data.length) {
            copyBusinessInfo(data);
        } else {
            getBusinesses.mutate();
        }
    };

    return (
        <Button
            appearance="contained"
            shape="rounded"
            size="medium"
            variant="primary"
            dataTrackId="admin_diffusion_cleaner__copy_bulk_button"
            onClick={onClick}
        >
            {t('admin:page_diffusion_cleaner__copy_button__bulk')}
        </Button>
    );
};
