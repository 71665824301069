import styled from '@emotion/styled';
import { HEX_COLORS, greyColorObject } from '@partoohub/branding';
import { Text } from '@partoohub/ui';

export const Footer = styled.div`
    position: sticky;
    bottom: 0;
    background-color: ${HEX_COLORS.white};
    border-top: 1px solid ${greyColorObject.initial};

    margin-top: auto;
    padding: 16px;
`;

export const HeaderText = styled(Text)`
    margin-bottom: 8px;
`;

export const LeftSideContent = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
