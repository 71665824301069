import { FC, useEffect, useState } from 'react';

import { Option, Text, toast } from '@partoohub/ui';
import { isEmpty, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import {
    CategoryData,
    CategoryDetailData,
    CategorySqlOnPublisherData,
    EditCategoryPayload,
    PublisherName,
} from 'app/api/types/categories';
import api from 'app/api/v2/api_calls';

import AdminPage from 'admin/common/components/templates/AdminPage';
import { STATIC_BASE_URL } from 'admin/common/data/config';

import { CATEGORIES } from 'admin/common/data/queryKeysConstants';

import { buildQueryParams } from 'admin/content/categories/pages/CategoryList/helpers';
import { CancelButton } from 'admin/content/categories/pages/common/CancelButton/CancelButton';
import { CategorySaveButton } from 'admin/content/categories/pages/common/CategorySaveButton/CategorySaveButton';
import { CategoryTranslation } from 'admin/content/categories/pages/common/CategoryTranslation/CategoryTranslation';
import { GoBackButton } from 'admin/content/categories/pages/common/GoBackButton/GoBackButton';
import { useFromPage } from 'admin/content/categories/pages/common/hooks/useFromPage';

import {
    EditCategoryNameContainer,
    EditCategoryTitleBox,
    GmbImageBox,
} from './CategoryEdit.styled';
import { EditCategorySettings } from './EditCategorySettings/EditCategorySettings';
import { OnPublishersForm } from './OnPublishersForm/OnPublishersForm';

const getEditTitleFromCategory = (category: CategoryData): string => {
    if (!category) return '';
    if (category.missing_mappings) return 'admin_categories_missingmapping_listing_title';
    return 'admin_categories_edit_title';
};

const getInfoLinkFromCategory = (category: CategoryData): string => {
    if (!category) return '';
    if (category.missing_mappings)
        return 'https://partoo.elium.com/tile/view/278/#b8e88cf3-2013-4599-aaec-aec409390735';
    return 'https://partoo.elium.com/tile/view/278/#eae9a9ea-b496-4165-9245-1b4654f498b4';
};

const formatCategoryOnPublishers = (
    categoryOnPublishers: Record<PublisherName, CategorySqlOnPublisherData>,
): Record<string, Option | null> => {
    const onPublishers: Record<string, Option | null> = {};

    for (const [publisherName, publisherCategory] of Object.entries(categoryOnPublishers)) {
        if (publisherCategory.id) {
            onPublishers[publisherName] = {
                label: `${publisherCategory.label} (${publisherCategory.publisher_id})`,
                value: publisherCategory.id.toString(),
                name: publisherCategory.id.toString(),
            };
        } else {
            onPublishers[publisherName] = null;
        }
    }
    return onPublishers;
};

export const CategoryEdit: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { goBack } = useFromPage(true);

    const [category, setCategory] = useState<CategoryDetailData | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<CategoryData | null>(null);
    const [publishers, setPublishers] = useState<PublisherName[]>([]);
    const [selectedCategoriesByPublisher, setSelectedCategoriesByPublisher] = useState<
        Record<string, Option | null>
    >({});
    const [defaultCategoriesByPublisher, setDefaultCategoriesByPublisher] = useState<
        Record<string, Option | null>
    >({});
    const [blockPushLong, setBlockPushLong] = useState<boolean>(false);

    const { categoryId } = useParams();

    const { data, isLoading } = useQuery([CATEGORIES, { categoryId }], async () => {
        const searchParams =
            location.state && location.state.from && new URLSearchParams(location.state.from);
        return categoryId
            ? await api.categories.getCategoryDetails(
                  parseInt(categoryId),
                  searchParams ? buildQueryParams(searchParams) : {},
              )
            : undefined;
    });

    useEffect(() => {
        if (data) {
            setCategory(data);
        }
    }, [data]);

    useEffect(() => {
        if (category) {
            setBlockPushLong(category.block_push_long_description_on_gmb);
            setPublishers(
                category.on_publishers
                    ? (Object.keys(category.on_publishers).sort((a, b) =>
                          a.localeCompare(b),
                      ) as Array<PublisherName>)
                    : [],
            );
            const onPublishersDefault = category.on_publishers
                ? formatCategoryOnPublishers(category.on_publishers)
                : {};
            setDefaultCategoriesByPublisher(onPublishersDefault);
            setSelectedCategoriesByPublisher(onPublishersDefault);
        }
    }, [category]);

    const translatedName = category?.name || t('admin_categories_missinglabel');
    const disabled =
        isEmpty(category) ||
        (isEqual(selectedCategoriesByPublisher, defaultCategoriesByPublisher) &&
            blockPushLong === category.block_push_long_description_on_gmb);

    const { mutate } = useMutation(
        (payload: EditCategoryPayload) =>
            api.categories.editCategory((category as CategoryDetailData).id, payload),
        {
            onError: () => {
                toast.error(
                    t('admin:page_category__edit__error_toast__title'),
                    t('admin:page_category__edit_error_toast__description'),
                );
            },
            onSuccess: () => {
                goBack();
                toast.success(
                    t('admin:page_category__edit_toast__title'),
                    t('admin:page_category__edit_toast__content'),
                );
            },
        },
    );

    const displayCancelButton =
        selectedCategory &&
        selectedCategory.gmb_id !== category?.gmb_id &&
        selectedCategory.gmb_id !== category?.live_category?.gmb_id;

    const onSubmit = () => {
        const payload = {
            on_publishers: Object.values(selectedCategoriesByPublisher)
                .filter((option): option is Option => option !== null)
                .map(option => parseInt(option.value)),
            block_push_long_description_on_gmb: blockPushLong,
        };
        mutate(payload);
    };

    return (
        <>
            {category && !isLoading && (
                <AdminPage
                    dataTrackId="page_category_edit"
                    title={t(getEditTitleFromCategory(category))}
                    infoLink={getInfoLinkFromCategory(category)}
                    buttons={
                        displayCancelButton ? (
                            <CancelButton onClick={() => setSelectedCategory(null)} />
                        ) : (
                            <></>
                        )
                    }
                >
                    <GoBackButton />
                    <EditCategoryTitleBox>
                        <GmbImageBox>
                            <img
                                src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/google_my_business.svg`}
                                alt="gmb"
                            />
                        </GmbImageBox>
                        <EditCategoryNameContainer>
                            <Text variant="heading6">{t('admin_categories_obsolete_GMB')}</Text>
                            <Text variant="bodyMRegular" color="secondary">
                                {`${t(translatedName)} (${category.gmb_id})`}
                            </Text>
                        </EditCategoryNameContainer>
                    </EditCategoryTitleBox>
                    <CategoryTranslation selectedCategory={category} />
                    <OnPublishersForm
                        category={category}
                        publishers={publishers}
                        selectedCategoriesByPublisher={selectedCategoriesByPublisher}
                        onChange={setSelectedCategoriesByPublisher}
                    />
                    <EditCategorySettings
                        isChecked={blockPushLong}
                        category={category}
                        onClickCheckBox={setBlockPushLong}
                    />
                    <CategorySaveButton disabled={disabled} onClick={onSubmit} />
                </AdminPage>
            )}
        </>
    );
};
