import { Option } from '@partoohub/ui';

export const getSelectedOptions = (arr: Record<string, boolean>) => {
    return Object.keys(arr).filter(key => !!arr[key]);
};

/** Retrieve the option selected in a single Filters component */
export const getSingleFilterSelectedOption = (arr: Record<string, boolean>) => {
    return Object.keys(arr).find(key => !!arr[key]);
};

/** Retrieve the option selected in a single Select component */
export const getSingleSelectSelectedOption = (arr: Option[]) => {
    return arr[0]?.value;
};
