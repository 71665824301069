import React, { useEffect, useState } from 'react';

import { CustomFile, DropzoneTypeEnum, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import MessagingBulkImportAdminApiCall from 'app/api/v2/api_calls/messagingBulkImportApiCalls';

import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import EmailInput from 'admin/common/components/inputs/EmailInput';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { useMe } from 'admin/common/hooks/queries/useMe';

const MAX_SIZE = 5_000_000;
const ACCEPTED_FILES = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

const MessagingImporter: React.FC = () => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<CustomFile[]>([]);
    const [email, setEmail] = useState('');

    const isFormValid = files?.length && email;

    const { mutate, isLoading } = useMutation(MessagingBulkImportAdminApiCall.bulkImport, {
        onSuccess: () => {
            toast.loading(
                t('admin:page_importer__success_toast__content'),
                t('admin:page_importer__success_toast__title'),
            );
            resetForm();
        },
        onError: (error: any) => {
            toast.error(error.message, t('admin:toast_error__title'));
        },
    });

    const submitForm = async () => {
        if (!isFormValid) return;
        mutate({ email, file: files[0] });
    };

    const resetForm = () => {
        setFiles([]);
    };

    const { data: user } = useMe();

    useEffect(() => {
        setEmail(user?.email ?? '');
    }, [user]);

    return (
        <AdminPage
            dataTrackId="admin_messages_importer"
            title={t('admin:page_messages_importer__title')}
            description={t('admin:page_messages_importer__description')}
            infoLink={'https://partoo.elium.com/tile/view/847'}
        >
            <BorderedContainer>
                <PageFormRow marginBottom={20}>
                    <DropZoneWithoutUpload
                        title={t('drop_zone_label')}
                        acceptedDescription={
                            <DropZoneDescription
                                maxSize={MAX_SIZE}
                                acceptedExtensions={ACCEPTED_FILES}
                            />
                        }
                        type={DropzoneTypeEnum.Document}
                        files={files}
                        setFiles={setFiles}
                        full
                        maxSize={MAX_SIZE}
                        multiple={false}
                        accept={ACCEPTED_FILES}
                    />
                </PageFormRow>
                <PageFormRow>
                    <EmailInput
                        placeholder={t('admin:receiving_email')}
                        email={email}
                        onChange={setEmail}
                        isMandatory
                        hideExplanation
                    />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_messages_importer__import_data"
                text={t('admin:page_importer__button_submit')}
                onClick={submitForm}
                disabled={!isFormValid}
                loading={isLoading}
            />
        </AdminPage>
    );
};

export default MessagingImporter;
