import React, { useCallback, useEffect, useMemo, useReducer } from 'react';

import { MultiSelect, Option, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { BulkExportAdminApiCall } from 'app/api/v2/api_calls/bulkExportApiCalls';

import { BulkTypeSelect, bulkTypeEnum } from 'admin/common/components/inputs/BulkTypeSelect';
import { BusinessStatusSelect } from 'admin/common/components/inputs/BusinessStatusSelect';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { NewProviderSelect } from 'admin/common/components/inputs/ProviderSelect/NewProviderSelect';
import { SingleSelect } from 'admin/common/components/inputs/SingleSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { languagesData } from 'admin/common/data/languages';
import { useMe } from 'admin/common/hooks/queries/useMe';

import {
    BulkFormatSelect,
    bulkFormatEnum,
    bulkFormatOptions,
} from 'admin/content/data/pages/Exporter/components/BulkFormatSelect';

import AdvancedSettings from './components/AdvancedSettings';
import { Subscribed, SubscribedInput } from './components/SubscribedInput';

interface Form {
    org?: Option;
    provider?: Option;
    bulkType: string | null;
    bulkFormat: string | null;
    status: string | null;
    selectedSections: Option[];
    hasActivePM: Subscribed;
    hasActiveRM: Subscribed;
    hasActiveRB: Subscribed;
    hasActiveBM: Subscribed;
    lang: string;
}

const formInitialValues = {
    org: undefined,
    provider: undefined,
    bulkType: null,
    bulkFormat: bulkFormatOptions[0].value,
    status: null,
    selectedSections: [],
    hasActivePM: undefined,
    hasActiveRM: undefined,
    hasActiveRB: undefined,
    hasActiveBM: undefined,
    lang: 'en',
};

const Exporter: React.FC = () => {
    const { t } = useTranslation();

    const [formValues, setFormValues] = useReducer(
        (prevState: Form, newState: Partial<Form>): Form => {
            return { ...prevState, ...newState };
        },
        formInitialValues,
    );
    const orgId = formValues.org?.value;
    const providerId = formValues.provider?.value;

    const isFormValid = useMemo(
        () => (!!orgId || !!providerId) && !!formValues.bulkType && !!formValues.bulkFormat,
        [formValues],
    );

    const businessSections: {
        label: string;
        name: string;
        value: string;
        excludeByDefault?: boolean;
    }[] = useMemo(
        () => [
            {
                label: t('bulk_category_hours'),
                name: 'hours',
                value: 'hours',
            },
            {
                label: t('categories'),
                name: 'categories',
                value: 'categories',
            },
            {
                label: t('contact'),
                name: 'contact',
                value: 'contact',
            },
            {
                label: t('bulk_category_description'),
                name: 'descriptions',
                value: 'descriptions',
            },
            {
                label: t('urls'),
                name: 'urls',
                value: 'urls',
            },
            {
                label: t('photos'),
                name: 'photos',
                value: 'photos',
            },
            {
                label: t('business_configuration'),
                name: 'business_configuration',
                value: 'business_configuration',
            },
            {
                label: t('product_subscription'),
                name: 'product_subscription',
                value: 'product_subscription',
            },
            {
                label: t('delete_business'),
                name: 'delete_business',
                value: 'delete_business',
                excludeByDefault: true,
            },
        ],
        [t],
    );

    const sectionOptions = useMemo(
        () =>
            formValues.bulkType === bulkTypeEnum.LOCATION &&
            formValues.bulkFormat === bulkFormatEnum.DEFAULT
                ? businessSections
                : [],
        [businessSections, formValues.bulkType, formValues.bulkFormat],
    );

    const langOptions: Option[] = useMemo(
        () =>
            languagesData
                .filter(lang => lang.supportedForBulkExportBusiness)
                .map(({ code, flag }) => ({
                    label: flag + ' ' + t(`original-${code}`),
                    value: code,
                    name: code,
                })),
        [t, languagesData],
    );

    const { data: user } = useMe();

    useEffect(() => {
        if (user) setFormValues({ lang: user.lang });
    }, [user]);

    useEffect(() => {
        setFormValues({ bulkFormat: bulkFormatEnum.DEFAULT });
    }, [formValues.bulkType]);

    useEffect(() => {
        setFormValues({
            selectedSections:
                formValues.bulkType === bulkTypeEnum.LOCATION &&
                formValues.bulkFormat === bulkFormatEnum.DEFAULT
                    ? businessSections.filter(section => !section.excludeByDefault)
                    : [],
        });
    }, [formValues.bulkType, formValues.bulkFormat]);

    const resetForm = useCallback(
        () =>
            setFormValues({
                ...formInitialValues,
                bulkType: formValues.bulkType,
                bulkFormat: formValues.bulkFormat,
            }),
        [],
    );

    const handleBulkTypeSelectChange = useCallback(
        (bulkType: string | null): void => setFormValues({ bulkType }),
        [formValues.bulkType],
    );

    const handleLangChange = useCallback(
        (lang: string | null): void => {
            if (lang) setFormValues({ lang: lang });
        },
        [formValues.lang],
    );

    const submitForm = useCallback(async () => {
        if (!isFormValid) {
            return;
        }
        try {
            if (formValues.bulkType === bulkTypeEnum.LOCATION) {
                await BulkExportAdminApiCall.bulkBusinessExport({
                    org_id: orgId,
                    provider: providerId,
                    bulk_format: formValues.bulkFormat!,
                    status: formValues.status || undefined,
                    sections__in:
                        formValues.bulkFormat === bulkFormatEnum.DEFAULT
                            ? formValues.selectedSections.map(choice => choice.value).join(',')
                            : undefined,
                    is_presence_management_subscriber: formValues.hasActivePM,
                    is_review_management_subscriber: formValues.hasActiveRM,
                    is_review_booster_subscriber: formValues.hasActiveRB,
                    is_business_message_subscriber: formValues.hasActiveBM,
                    language: formValues.lang,
                });
                toast.loading(
                    t('admin:page_exporter__success_toast__content'),
                    t('admin:page_exporter__success_toast__title'),
                );
            } else if (formValues.bulkType === bulkTypeEnum.USER) {
                await BulkExportAdminApiCall.bulkUserExport({
                    org_id: orgId,
                    provider: providerId,
                });
                toast.loading(
                    t('admin:page_exporter__success_toast__content'),
                    t('admin:page_exporter__success_toast__title'),
                );
            } else {
                toast.error('Not implemented', t('admin:toast_error__title'));
            }
        } catch (e: any) {
            toast.error(e.message, t('admin:toast_error__title'));
        } finally {
            resetForm();
        }
    }, [formValues]);

    return (
        <AdminPage
            dataTrackId="page_exporter"
            title={t('admin:page_exporter__title')}
            description={t('admin:page_exporter__description')}
            infoLink={'https://partoo.elium.com/tile/view/275'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <BulkTypeSelect
                        placeholder={t('admin:page_exporter__select_bulk_type')}
                        selectedValue={formValues.bulkType}
                        onChange={handleBulkTypeSelectChange}
                    />
                </PageFormRow>
                {formValues.bulkType && (
                    <PageFormRow>
                        <NewProviderSelect
                            dataTrackId="page_exporter__filter_provider"
                            provider={formValues.provider}
                            setProvider={(selectedItem?: Option) =>
                                setFormValues({ provider: selectedItem, org: undefined })
                            }
                        />
                        <NewOrganizationSelect
                            dataTrackId="page_exporter__filter_organization"
                            orgs={formValues.org}
                            setOrgs={(selectedItems?: Option) =>
                                setFormValues({ org: selectedItems })
                            }
                            placeholder={t('admin:organization')}
                            queryParams={{
                                provider: providerId,
                            }}
                        />
                    </PageFormRow>
                )}
                {formValues.bulkType === bulkTypeEnum.LOCATION && (
                    <>
                        <PageFormRow>
                            <BulkFormatSelect
                                bulkFormat={formValues.bulkFormat}
                                setBulkFormat={(value: string | null) =>
                                    setFormValues({ bulkFormat: value })
                                }
                            />
                        </PageFormRow>
                        <PageFormRow>
                            <MultiSelect
                                dataTrackId=""
                                placeholder=""
                                sections={[{ options: sectionOptions }]}
                                label={t('admin:page_exporter__sections_to_export')}
                                onChange={(value: Option[]) =>
                                    setFormValues({ selectedSections: value })
                                }
                                selectedValue={formValues.selectedSections}
                                disabled={formValues.bulkFormat != bulkFormatEnum.DEFAULT}
                                maxHeight={300}
                                max={8}
                            />
                        </PageFormRow>
                        <AdvancedSettings title={t('admin:page_exporter__advanced_settings')}>
                            <PageFormRow>
                                <SingleSelect
                                    dataTrackId="page_exporter__select language"
                                    placeholder={t('admin:page_demo_creator__select_language')}
                                    selectedValue={formValues.lang}
                                    sections={[{ options: langOptions }]}
                                    onChange={handleLangChange}
                                    disableReset
                                />
                            </PageFormRow>
                            <PageFormRow>
                                <SubscribedInput
                                    dataTrackId="page_exporter__subscribe_input__pm"
                                    selectedValue={formValues.hasActivePM}
                                    onChange={(value: Subscribed) =>
                                        setFormValues({ hasActivePM: value })
                                    }
                                    placeholder={`${t('admin:filter_by')} Presence Management`}
                                />
                                <SubscribedInput
                                    dataTrackId="page_exporter__subscribe_input__rm"
                                    selectedValue={formValues.hasActiveRM}
                                    onChange={(value: Subscribed) =>
                                        setFormValues({ hasActiveRM: value })
                                    }
                                    placeholder={`${t('admin:filter_by')} Review Management`}
                                />
                            </PageFormRow>
                            <PageFormRow>
                                <SubscribedInput
                                    dataTrackId="page_exporter__subscribe_input__rb"
                                    selectedValue={formValues.hasActiveRB}
                                    onChange={(value: Subscribed) =>
                                        setFormValues({ hasActiveRB: value })
                                    }
                                    placeholder={`${t('admin:filter_by')} Review Booster`}
                                />
                                <SubscribedInput
                                    dataTrackId="page_exporter__subscribe_input__bm"
                                    selectedValue={formValues.hasActiveBM}
                                    onChange={(value: Subscribed) =>
                                        setFormValues({ hasActiveBM: value })
                                    }
                                    placeholder={`${t('admin:filter_by')} Business Messages`}
                                />
                            </PageFormRow>
                            {formValues.bulkType === bulkTypeEnum.LOCATION && (
                                <PageFormRow>
                                    <BusinessStatusSelect
                                        status={formValues.status}
                                        setStatus={(value: string | null) =>
                                            setFormValues({ status: value })
                                        }
                                    />
                                </PageFormRow>
                            )}
                        </AdvancedSettings>
                    </>
                )}
            </BorderedContainer>
            <SubmitButton
                dataTrackId={
                    formValues.bulkType ? `admin_exporter__export_${formValues.bulkType}_data` : ''
                }
                disabled={!isFormValid}
                text={t('admin:page_exporter__button_export_data')}
                onClick={submitForm}
            />
        </AdminPage>
    );
};

export default Exporter;
