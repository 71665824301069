import { FC, useState } from 'react';

import { CustomFile, DropzoneTypeEnum, Switch, Text, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { MigrateGroupsApiCall } from 'app/api/v2/api_calls/bulkImportApiCalls';

import { DropZoneDescription } from 'admin/common/components/inputs/DropZone/DropZoneDescription';
import { DropZoneWithoutUpload } from 'admin/common/components/inputs/DropZone/DropzoneWithoutUpload';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import { SwitchInputWrapper } from 'admin/common/components/layout/SwitchWrapper/SwitchWrapper.styled';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { useMe } from 'admin/common/hooks/queries/useMe';

const WRITE_MODIFICATION_DEFAULT = false;
const MAX_SIZE = 5_000_000;
const ACCEPTED_FILES = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
};

const GroupMigration: FC = () => {
    const { t } = useTranslation();
    const { data: me } = useMe();

    const [files, setFiles] = useState<CustomFile[]>([]);
    const [fileHasError, setFileHasError] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [writeModification, setWriteModification] = useState(WRITE_MODIFICATION_DEFAULT);
    const isFormValid = files?.length && !fileHasError;

    const submitForm = async () => {
        if (!isFormValid) return;
        setIsFormLoading(true);

        try {
            toast.loading(
                t('admin:page_importer__success_toast__content'),
                t('admin:page_importer__success_toast__title'),
            );
            await MigrateGroupsApiCall.migrateGroups(
                files[0] as Blob,
                me?.email,
                writeModification,
            );
        } catch (e: any) {
            toast.error(e.message, t('admin:toast_error__title'));
        } finally {
            resetForm();
        }
    };

    const resetForm = () => {
        setFiles([]);
        setFileHasError(false);
        setIsFormLoading(false);
        setWriteModification(WRITE_MODIFICATION_DEFAULT);
    };

    const toggleWriteModification = () => {
        setWriteModification(!writeModification);
    };

    return (
        <AdminPage
            dataTrackId="admin__group_migration"
            title={'Groups Migration V2'}
            description={
                'This page is used to migrate organizations to the new group system. WARNING: Do not run this if you don\'t know what it is.'
            }
        >
            <BorderedContainer>
                <PageFormRow marginBottom={24}>
                    <DropZoneWithoutUpload
                        title={t('drop_zone_label')}
                        acceptedDescription={
                            <DropZoneDescription
                                maxSize={MAX_SIZE}
                                acceptedExtensions={ACCEPTED_FILES}
                            />
                        }
                        type={DropzoneTypeEnum.Document}
                        files={files}
                        setFiles={setFiles}
                        multiple={false}
                        maxSize={MAX_SIZE}
                        full
                        accept={ACCEPTED_FILES}
                    />
                </PageFormRow>
            </BorderedContainer>
            <SwitchInputWrapper>
                <Switch
                    dataTrackId="admin__group_migration__toggle_write_modifications"
                    name="toggle_write_modifications"
                    onChange={toggleWriteModification}
                    checked={writeModification}
                    label={
                        <Text variant="bodyMBold" as="span" color="secondary">
                            {t('admin:page_group_migration__toggle_write_modifications')}
                        </Text>
                    }
                />
            </SwitchInputWrapper>
            <SubmitButton
                dataTrackId={'admin__group_migration__submit'}
                text={t('admin:page_group_migration__button_submit')}
                onClick={submitForm}
                disabled={!isFormValid}
                loading={isFormLoading}
            />
        </AdminPage>
    );
};

export default GroupMigration;
