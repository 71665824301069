import { FC, useEffect, useState } from 'react';

import { Option, Section, SingleSelect, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';

import { SearchUserParams, V2UserData } from 'app/api/types/user';
import User from 'app/api/v2/api_calls/userApiCalls';

import { USERS } from 'admin/common/data/queryKeysConstants';

import { convertUserToOption } from './convertUserToOption';
import { EmptyMessageWrapper } from '../EmptyMessage';

type Props = {
    dataTrackId: string;
    searchParams?: SearchUserParams;
    placeholder?: string;
    filterUser?: (user: V2UserData) => boolean;
    required?: boolean;
    user?: Option;
    setUser: (selectedItems?: Option) => void;
};

export const NewUserSelect: FC<Props> = ({
    dataTrackId,
    searchParams,
    placeholder = 'User',
    user,
    setUser,
    filterUser = () => true,
    required,
}: Props) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [section, setSection] = useState<Section[]>([]);
    const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
        [USERS, { ...searchParams, searchValue }],
        ({ pageParam = 1 }) =>
            User.searchUsers({
                ...(searchParams || {}),
                query: searchValue,
                page: pageParam,
            }),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            keepPreviousData: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );
    const user_list = data?.pages?.map(({ users }) => users)?.flat();

    useEffect(() => {
        if (!isLoading) {
            const options =
                user_list?.filter(filterUser)?.map(user => convertUserToOption(user)) || [];
            const section = [{ options: options }];
            setSection(section);
        }
    }, [data]);

    const handleChange = (selectedOptions?: Option): void => {
        setUser(selectedOptions);
    };

    const handleSearch = (text: string) => {
        setSearchValue(text.split('mailto:').pop() as string);
    };

    return (
        <SingleSelect
            dataTrackId={dataTrackId}
            label={placeholder}
            sections={section}
            placeholder=""
            selectedValue={user}
            onChange={handleChange}
            onSearch={handleSearch}
            maxHeight={300}
            emptyMessage={
                <EmptyMessageWrapper>
                    <Text variant="bodyMMedium">
                        {t('admin:select_user__empty_message')} “{searchValue}“
                    </Text>
                </EmptyMessageWrapper>
            }
            required={required}
            loadMore={fetchNextPage}
            hasMore={hasNextPage}
        />
    );
};
