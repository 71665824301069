import AdminApiResourceBaseClass from './baseAdminApiCalls';
import {
    CompetitorWithId,
    CreateNewCompetitorPayloadWithOrgId,
    DeleteCompetitorPayload,
    KeywordAdminData,
    KeywordAdminDataRaw,
    UpdateCompetitorPayload,
} from '../types/competitiveBenchmark';

const RESOURCE_URI = 'competitive-benchmark';

export class CompetitiveBenchmark extends AdminApiResourceBaseClass {
    static getKeywordsFromOrg(orgId: number): Promise<KeywordAdminDataRaw> {
        return CompetitiveBenchmark.get(`${RESOURCE_URI}/keywords/${orgId}`).then(
            ({ data }) => data,
        );
    }

    static createNewKeyword(orgId: number, keyword: string): Promise<KeywordAdminData> {
        return CompetitiveBenchmark.post(`${RESOURCE_URI}/keywords/${orgId}`, {
            keyword,
        }).then(({ data }) => {
            return data;
        });
    }

    static toggleKeyword(orgId: number, keyword: KeywordAdminData): Promise<KeywordAdminData> {
        return CompetitiveBenchmark.patch(`${RESOURCE_URI}/keywords/${orgId}`, {
            id: keyword.id,
            active: !keyword.active,
        }).then(({ data }) => {
            return data;
        });
    }

    static createCompetitor(
        payload: CreateNewCompetitorPayloadWithOrgId,
    ): Promise<CompetitorWithId> {
        const { orgId, keywordId, name, included, excluded } = payload;
        return CompetitiveBenchmark.post(`${RESOURCE_URI}/competitors`, {
            name,
            keyword_id: keywordId,
            org_id: orgId,
            included,
            excluded,
        }).then(({ data }) => {
            return data;
        });
    }

    static deleteCompetitor(payload: DeleteCompetitorPayload): Promise<CompetitorWithId> {
        const { competitor } = payload;
        return CompetitiveBenchmark.delete(`${RESOURCE_URI}/competitors/${competitor.id}`).then(
            ({ data }) => {
                return data;
            },
        );
    }

    static updateCompetitor(payload: UpdateCompetitorPayload): Promise<CompetitorWithId> {
        const { competitor } = payload;
        return CompetitiveBenchmark.put(`${RESOURCE_URI}/competitors/${competitor.id}`, {
            ...competitor,
        }).then(({ data }) => {
            return data;
        });
    }
}
