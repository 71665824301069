import { camelCase } from 'lodash-es';

import { V2FormattedLocationData, V2LocationData } from 'app/api/types/account';

const formatObjectKeysToCamelCase = (
    obj: Record<string, any> | Array<Record<string, any>>,
): Record<string, any> => {
    if (Array.isArray(obj)) {
        return obj.map(formatObjectKeysToCamelCase);
    }

    if (typeof obj === 'object') {
        return Object.keys(obj).reduce(
            (formattedObj, key) => ({
                ...formattedObj,
                [camelCase(key)]: obj[key] && formatObjectKeysToCamelCase(obj[key]),
            }),
            {},
        );
    }

    return obj;
};

export const convertLocationToCamelCase = (location: V2LocationData): V2FormattedLocationData => {
    return formatObjectKeysToCamelCase(location) as V2FormattedLocationData;
};
