import React, { useState } from 'react';

import { Button, Drawer, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import {
    DiffusionCleanerAdminApiCall,
    DiffusionCleanerStatusData,
} from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DIFFUSION_CLEANER_UNKNOWN_ERROR } from 'admin/common/data/queryKeysConstants';

import { useMe } from 'admin/common/hooks/queries/useMe';

import { DrawerHeading, DrawerWrapper, ResponseContent } from './UnknownErrorButton.styled';

type Props = {
    business: DiffusionCleanerStatusData;
};

export const UnknownErrorButton = ({ business }: Props) => {
    const { t } = useTranslation();
    const me = useMe();
    const { isSuccess, data } = useQuery({
        queryKey: [DIFFUSION_CLEANER_UNKNOWN_ERROR, business.call_log_id],
        queryFn: () =>
            DiffusionCleanerAdminApiCall.getResponseContent({
                call_log_id: business.call_log_id,
            }),
        enabled: me.isSuccess,
    });
    const [open, setOpen] = useState(false);

    return (
        <>
            <Drawer
                isOpen={open}
                onHide={() => setOpen(false)}
                dataTrackId="admin_diffusion_cleaner__unknown_error__drawer"
            >
                <DrawerWrapper>
                    <DrawerHeading>
                        <Text variant="heading5">
                            {t('admin:page_diffusion_cleaner__unknown_error__heading')}
                        </Text>
                    </DrawerHeading>
                    <ResponseContent>
                        {isSuccess && (
                            <Text variant="bodyLRegular">
                                {data.response_content}
                                {/* {JSON.parse(data.response_content)} */}
                            </Text>
                        )}
                    </ResponseContent>
                </DrawerWrapper>
            </Drawer>
            <Button
                shape="rounded"
                size="medium"
                variant="theme"
                dataTrackId={'admin_diffusion_cleaner__business_button__unknown_error'}
                onClick={() => setOpen(!open)}
            >
                <Text variant="bodyMBold" color="white">
                    {t('admin:page_diffusion_cleaner__business_button__unknown_error')}
                </Text>
            </Button>
        </>
    );
};
