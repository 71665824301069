import { useMutation } from 'react-query';

import api from 'admin/common/api/calls';
import {
    KeywordAdminData,
    UpdateKeywordPayload,
} from 'admin/common/api/types/competitiveBenchmark';

export const useToggleKeyword = (
    onSuccess: (keyword: KeywordAdminData) => void,
): { isLoading: boolean; mutate: (payload: UpdateKeywordPayload) => Promise<KeywordAdminData> } => {
    const { mutate: updateKeyword, isLoading } = useMutation(
        (payload: UpdateKeywordPayload): Promise<KeywordAdminData> => {
            const { orgId, keyword } = payload;
            return api.competitiveBenchmark.toggleKeyword(orgId, keyword);
        },
        {
            onSuccess,
        },
    );

    return {
        mutate: updateKeyword as (payload: UpdateKeywordPayload) => Promise<KeywordAdminData>,
        isLoading,
    };
};
