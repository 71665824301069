import { SuccessResponse } from 'app/api/types';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';

const RESOURCE_URI = 'messaging_bulk_uploader';

type MessagingImportAdminData = {
    email: string;
    file: Blob;
};

class MessagingBulkImportAdminApiCall extends AdminApiResourceBaseClass {
    static bulkImport(
        data: MessagingImportAdminData,
    ): Promise<SuccessResponse> {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('email', data.email);

        return MessagingBulkImportAdminApiCall.post(
            `${RESOURCE_URI}`,
            formData,
        ).then(({ data }) => data);
    }
}

export default MessagingBulkImportAdminApiCall;
