import AdminApiResourceBaseClass from 'admin/common/api/calls/baseAdminApiCalls';

const RESOURCE_URI = 'csinterface_uploader';

type BulkImportV1Inputs = {
    file: Blob;
    email: string;
    integration: string;
    do_save?: boolean;
};

class BulkImportV1ApiCall extends AdminApiResourceBaseClass {
    static bulkImportV1(inputs: BulkImportV1Inputs): Promise<any> {
        const formData = new FormData();
        formData.append('file', inputs.file);
        formData.append('integration', inputs.integration);
        formData.append('email', inputs.email);
        if (inputs.do_save) formData.append('do_save', 'on');

        return BulkImportV1ApiCall.post(`${RESOURCE_URI}`, formData).then(({ data }) => data);
    }
}

export default BulkImportV1ApiCall;
