import { AppApiResourceBaseClass } from './baseAppApiCalls';
import { OrgGroupConfig } from '../types/org';

const RESOURCE_URI = 'org';

export class Organization extends AppApiResourceBaseClass {
    static getGroupsConfig(orgId: string | number): Promise<OrgGroupConfig> {
        return Organization.get(`${RESOURCE_URI}/${orgId}/groups_config`).then(({ data }) => data);
    }
}
