import { useState } from 'react';

import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, List, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CompetitorWithId, KeywordAdminData } from 'admin/common/api/types/competitiveBenchmark';

import { Row, RowCssContainer, RowTitle } from './CompetitorList.styled';
import { DeleteCompetitorModal } from './DeleteCompetitorModal';
import { EditCompetitorModal } from './EditCompetitorModal';
import { useGetCompetitorListByKeywordId } from '../utils/useGetCompetitorListByKeywordId';

type Props = {
    keyword: KeywordAdminData;
};

export const CompetitorList = ({ keyword }: Props) => {
    const [editingCompetitor, setEditingCompetitor] = useState<CompetitorWithId | null>(null);
    const [deletingCompetitor, setDeletingCompetitor] = useState<CompetitorWithId | null>(null);
    const currentCompetitors = useGetCompetitorListByKeywordId(keyword.id);

    return (
        <>
            <List
                showHeader={false}
                columns={[{ width: '50%' }, { width: '20%' }, { width: '20%' }, { width: '10%' }]}
            >
                {currentCompetitors.map(competitor => (
                    <CompetitorRow
                        competitor={competitor}
                        onEdit={() => setEditingCompetitor(competitor)}
                        onDelete={() => setDeletingCompetitor(competitor)}
                        key={competitor.id}
                    />
                ))}
            </List>
            {editingCompetitor && (
                <EditCompetitorModal
                    onClose={() => setEditingCompetitor(null)}
                    opened={!!editingCompetitor}
                    competitor={editingCompetitor}
                    keywordId={keyword.id}
                />
            )}
            {deletingCompetitor && (
                <DeleteCompetitorModal
                    onClose={() => setDeletingCompetitor(null)}
                    opened={!!deletingCompetitor}
                    competitor={deletingCompetitor}
                />
            )}
        </>
    );
};

type CompetitorRowProps = {
    competitor: CompetitorWithId;
    onDelete: (id: string) => void;
    onEdit: (id: string) => void;
};

const CompetitorRow = ({ competitor, onEdit, onDelete }: CompetitorRowProps) => {
    const { t } = useTranslation();

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={() => onEdit(competitor.id)}
            onKeyDown={() => undefined}
        >
            <Row
                dataTrackId={competitor.name}
                id={competitor.name}
                rowCssContainer={RowCssContainer}
            >
                <List.Cell>
                    <RowTitle>
                        <Text
                            as="span"
                            variant="bodyMBold"
                            css={css`
                                text-transform: capitalize;
                            `}
                        >
                            {competitor.name}
                        </Text>
                    </RowTitle>
                </List.Cell>
                <List.Cell>
                    <Text as="span" variant="bodyMSemibold" color="secondary">
                        {t('admin:words_included', { count: competitor.included.length })}
                    </Text>
                </List.Cell>
                <List.Cell>
                    <Text as="span" variant="bodyMSemibold" color="secondary">
                        {t('admin:words_excluded', { count: competitor.excluded.length })}
                    </Text>
                </List.Cell>
                <List.Cell
                    css={css`
                        align-items: center;
                    `}
                >
                    <IconButton
                        icon={[FontAwesomeIconsPartooUsed.faTrash, IconPrefix.REGULAR]}
                        appearance="outlined"
                        dataTrackId={'delete_competitor_button'}
                        variant="danger"
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            onDelete(competitor.id);
                        }}
                    />
                </List.Cell>
            </Row>
        </div>
    );
};
