import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { ReceivedFeedbackFormData } from 'app/api/types/feedbackForm';
import api from 'app/api/v2/api_calls';

import { FEEDBACK_FORM } from 'admin/common/data/queryKeysConstants';

export const useGetFeedbackForm = (
    orgId: number,
    options: UseQueryOptions<ReceivedFeedbackFormData> = {},
) => {
    const query = useQuery(
        [FEEDBACK_FORM, orgId] as QueryKey,
        () => api.feedbackForm.getFeedbackForm(orgId),
        options,
    );

    const form = query?.data?.items?.[0];

    return [form, query] as const;
};
