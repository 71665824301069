import { FC, useState } from 'react';

import { Option, SingleSelect } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from 'react-query';

import { CategoryDetailData, PublisherName } from 'app/api/types/categories';
import api from 'app/api/v2/api_calls';

import { STATIC_BASE_URL } from 'admin/common/data/config';

import { CATEGORIES } from 'admin/common/data/queryKeysConstants';

import {
    OnPublisherFormIcon,
    OnPublisherFormIconNameContainer,
    OnPublisherFormName,
    OnPublisherFormSelect,
    OnPublisherFormSelectBox,
} from './OnPublishersForm.styled';

interface SelectPublisherCategoryProps {
    publisher: PublisherName;
    category: CategoryDetailData;
    selectedCategory?: Option;
    onChange: (category?: Option) => void;
}

export const SelectPublisherCategory: FC<SelectPublisherCategoryProps> = ({
    publisher,
    category,
    selectedCategory,
    onChange,
}) => {
    const { t } = useTranslation();
    const [userSearch, setUserSearch] = useState<string>('');

    const hasError = selectedCategory === null;

    const defaultSelectedValue: Option = {
        label: `${category.on_publishers[publisher].label} (${category.on_publishers[publisher].publisher_id})`,
        value: `${category.on_publishers[publisher].id}`,
        name: `${category.on_publishers[publisher].id}`,
    };
    const selectedValue = hasError ? null : selectedCategory || defaultSelectedValue;

    const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
        [CATEGORIES, { publisher, userSearch }],
        ({ pageParam = 1 }) =>
            api.categories.getCategoriesOnPublishers(
                {
                    label_query: userSearch,
                    publisher_name: publisher,
                },
                pageParam,
            ),
        {
            getNextPageParam: lastPage =>
                lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined,
            keepPreviousData: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    );
    const categories = data?.pages?.map(({ categories }) => categories)?.flat();

    const categoriesOptions: Option[] =
        categories?.map(cat => ({
            value: `${cat.id}`,
            name: `${cat.id}`,
            label: `${cat.label || t('admin_categories_missinglabel')} (${cat.publisher_id})`,
        })) || [];

    return (
        <OnPublisherFormSelectBox>
            <OnPublisherFormIconNameContainer>
                <OnPublisherFormIcon>
                    <img
                        src={`${STATIC_BASE_URL}/images/common/partners_sprite/circle/${publisher}.svg`}
                        alt={publisher}
                    />
                </OnPublisherFormIcon>
                <OnPublisherFormName>{publisher}</OnPublisherFormName>
            </OnPublisherFormIconNameContainer>
            <OnPublisherFormSelect>
                <SingleSelect
                    dataTrackId={`page_edit_category__select_category__${publisher}`}
                    sections={[{ options: categoriesOptions }]}
                    onChange={onChange}
                    selectedValue={selectedValue ?? undefined}
                    placeholder="Name"
                    label="Name"
                    error={hasError}
                    notice={hasError ? t('admin_categories_edit_missingmappings_flag') : ''}
                    maxHeight={300}
                    onSearch={setUserSearch}
                    loadMore={fetchNextPage}
                    hasMore={hasNextPage}
                />
            </OnPublisherFormSelect>
        </OnPublisherFormSelectBox>
    );
};
