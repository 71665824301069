import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationElement,
    IllustrationVariousEnum,
} from '@partoohub/iconography';
import { Button, Stack, Text } from '@partoohub/ui';
import { t } from 'i18next';

import { IllustrationContainer } from './KeywordsSectionPlaceholder.styled';

type Props = {
    action: (opened: boolean) => void;
};

export const KeywordsSectionPlaceholder = ({ action }: Props) => {
    const illustration: IllustrationElement = {
        type: IllustrationCategoryEnum.Various,
        name: IllustrationVariousEnum.AddKeyword,
    };

    return (
        <Stack direction="column" justifyContent="center" alignItems="center">
            <IllustrationContainer>
                <Illustration
                    dataTrackId="competitive_benchmark__add_keyword_asset"
                    illustration={illustration}
                    height="100%"
                    width="100%"
                />
            </IllustrationContainer>
            <Text variant="heading3" as="span">
                {t('admin:page_competitive_benchmark__add_keyword_title')}
            </Text>
            <Text variant="bodyLRegular" as="span" color="#7F8EA4">
                {t('admin:page_competitive_benchmark__add_keyword_subtitle')}
            </Text>
            <Button
                dataTrackId="competitive-benchmark-add-keyword-placeholder-action"
                appearance="contained"
                size="medium"
                variant="primary"
                onClick={() => action(true)}
            >
                {t('admin:page_competitive_benchmark__add_keyword_button')}
            </Button>
        </Stack>
    );
};
