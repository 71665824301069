import React from 'react';

import { Option, SingleSelect } from '@partoohub/ui';

type Props = {
    statuses?: Option;
    setStatuses: (selectedItems?: Option) => void;
};

export enum GoogleAgentStatusChoices {
    NO_AGENTS = 'NO_AGENTS',
    NOT_LAUNCHED = 'NOT_LAUNCHED',
    LAUNCHED = 'LAUNCHED',
}

const options = [
    {
        value: GoogleAgentStatusChoices.NO_AGENTS,
        name: GoogleAgentStatusChoices.NO_AGENTS,
        label: 'No agents',
    },
    {
        value: GoogleAgentStatusChoices.NOT_LAUNCHED,
        name: GoogleAgentStatusChoices.NOT_LAUNCHED,
        label: 'Not launched',
    },
    {
        value: GoogleAgentStatusChoices.LAUNCHED,
        name: GoogleAgentStatusChoices.LAUNCHED,
        label: 'Launched',
    },
];
const section = [{ options: options }];

export const GoogleAgentStatusSelect: React.FC<Props> = ({ statuses, setStatuses }) => (
    <SingleSelect
        dataTrackId="messaging-exporter-email"
        label="Status"
        sections={section}
        selectedValue={statuses}
        onChange={setStatuses}
        maxHeight={300}
    />
);
