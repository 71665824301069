import React, { useEffect, useState } from 'react';

import { Filters, FontAwesomeIconsPartooUsed, IconPrefix, Section } from '@partoohub/ui';
import { useQuery } from 'react-query';

import Group from 'app/api/v2/api_calls/groupsApiCalls';

import { GROUPS } from 'admin/common/data/queryKeysConstants';

type Props = {
    groups: Record<string, boolean>;
    setGroups: (selectedItems: Record<string, boolean>) => void;
    dataTrackId: string;
    orgId?: string;
    disabled?: boolean;
};

export const DeprecatedGroupSelect: React.FC<Props> = ({
    groups,
    setGroups,
    dataTrackId,
    orgId,
    disabled,
}: Props) => {
    const [section, setSection] = useState<Section[]>([]);
    const { data, isLoading } = useQuery(
        [GROUPS, { orgId }],
        async () => {
            const groupsList = await Group.getGroups(1, null, orgId);
            const options = groupsList.groups.map(group => ({
                value: group.id.toString(),
                name: group.id.toString(),
                label: group.name,
            }));
            const section = [{ options: options }];
            return section;
        },
        {
            staleTime: Infinity,
        },
    );
    useEffect(() => {
        if (!isLoading) {
            setSection(data || []);
        }
    }, [data]);

    return (
        <Filters
            dataTrackId={dataTrackId}
            menuListArray={section}
            selectedItems={groups}
            onChange={setGroups}
            placeholder={'Groups'}
            position="bottom"
            maxHeight={300}
            multiple={true}
            disabled={disabled}
            icon={[FontAwesomeIconsPartooUsed.faFolder, IconPrefix.SOLID]}
        />
    );
};
