import { Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { CategoryDetailData, PublisherName } from 'app/api/types/categories';

import {
    OnPublisherFormContent,
    OnPublishersFormContainer,
    StyledSubtitle,
} from './OnPublishersForm.styled';
import { SelectPublisherCategory } from './SelectPublisherCategory';

type Props = {
    publishers: Array<PublisherName>;
    category: CategoryDetailData;
    selectedCategoriesByPublisher: Record<string, Option | null>;
    onChange: (option: Record<string, Option | null>) => void;
};

export const OnPublishersForm = ({
    publishers,
    category,
    selectedCategoriesByPublisher,
    onChange,
}: Props) => {
    const { t } = useTranslation();

    const onCategorySelect = (publisher: PublisherName, category: Option | null) => {
        const newSelectedCategories = {
            ...selectedCategoriesByPublisher,
            [publisher]: category,
        };
        onChange(newSelectedCategories);
    };

    return (
        <OnPublishersFormContainer>
            <StyledSubtitle variant="heading6">
                {t('admin_categories_edit_mappings_publisherblock')}
            </StyledSubtitle>
            <OnPublisherFormContent>
                {publishers.map(publisher => (
                    <SelectPublisherCategory
                        key={publisher}
                        publisher={publisher}
                        category={category}
                        selectedCategory={selectedCategoriesByPublisher[publisher]}
                        onChange={value => onCategorySelect(publisher, value)}
                    />
                ))}
            </OnPublisherFormContent>
        </OnPublishersFormContainer>
    );
};
