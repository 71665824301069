import React from 'react';

import { IconPrefix } from '@partoohub/ui';

import { Draggable } from 'react-beautiful-dnd';

import { useTranslation } from 'react-i18next';

import { FoodMenuItemType } from 'admin/common/api/calls/bulkFoodMenuApiCalls';

import { FoodMenuMenu } from 'admin/content/data/pages/FoodMenusCreation/components/MenuSections/MenuItems/FoodMenuMenu';
import { MenuLabelItem } from 'admin/content/data/pages/FoodMenusCreation/components/MenuSections/MenuItems/MenuLabelItem';
import { SectionBody } from 'admin/content/data/pages/FoodMenusCreation/components/MenuSections/MenuItems/SectionBody';
import { LeftIcon } from 'admin/content/data/pages/FoodMenusCreation/components/MenuSections/MenuItems/SectionBody/SectionBody.styled';

import { MenuItemPhoto } from './MenuItem.styled';

interface MenuItemProps {
    itemIndex: any;
    currency: string;
    disabled: boolean;
    item: any;
    onEdit: (item: FoodMenuItemType) => void;
    onDelete: (item: FoodMenuItemType) => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
    itemIndex,
    currency,
    disabled,
    item,
    onEdit,
    onDelete,
}) => {
    const { t } = useTranslation();
    return (
        <Draggable
            key={`${item.name}_${item.order}`}
            draggableId={`${item.name}_${item.order}`}
            index={itemIndex}
            isDragDisabled={disabled}
        >
            {draggableRef => (
                <div
                    ref={draggableRef.innerRef}
                    {...draggableRef.draggableProps}
                    onMouseDown={e => e.currentTarget.focus()}
                >
                    <SectionBody
                        key={`${item.name}_${item.order}`}
                        id={`${item.name}_${item.order}`}
                        disabled={disabled}
                        mainLabel={item.name}
                        subLabel={
                            item.price
                                ? `${(Math.round(item.price * 100) / 100)
                                      .toString()
                                      .replace('.', ',')} ${currency}`
                                : undefined
                        }
                        leftIcon
                        leftIconElem={
                            <div {...draggableRef.dragHandleProps}>
                                <LeftIcon className="fas fa-bars" />
                            </div>
                        }
                        description={item.description}
                        optionalLabel={
                            item.media?.source_url ? (
                                <MenuItemPhoto src={item.media?.source_url} alt="photo-item" />
                            ) : null
                        }
                        menuElem={
                            <FoodMenuMenu
                                key={`food_menu_menu_${item.name}_for_${item.order}`}
                                onMenuClick={(value: string) => {
                                    if (value === 'edit') {
                                        onEdit(item);
                                    } else if (value === 'delete') {
                                        onDelete(item);
                                    }
                                }}
                                disabled={disabled}
                                options={[
                                    {
                                        name: 'edit',
                                        label: (
                                            <MenuLabelItem
                                                text={t('edit')}
                                                icon={['fa-pen-alt', IconPrefix.REGULAR]}
                                            />
                                        ),
                                        value: 'edit',
                                    },
                                    {
                                        name: 'delete',
                                        label: (
                                            <MenuLabelItem
                                                text={t('delete')}
                                                icon={['fa-trash-alt', IconPrefix.REGULAR]}
                                                color="danger"
                                            />
                                        ),
                                        value: 'delete',
                                    },
                                ]}
                            />
                        }
                    />
                </div>
            )}
        </Draggable>
    );
};
