import React, { FC } from 'react';

import { css } from '@emotion/react';

import { FontAwesomeIconsPartooUsed, IconButton, List, Stack, Text } from '@partoohub/ui';

import { V2UserData } from 'app/api/types/user';

type Props = {
    user: V2UserData;
};

const UserSubRow: FC<Props> = ({ user }: Props) => {
    return (
        <Stack direction="row" justifyContent="left">
            <Text variant="bodyMBold">Id</Text>
            <Text variant="bodyMRegular">{user.id}</Text>
            <Text variant="bodyMBold">Mail</Text>
            <Text variant="bodyMRegular">{user.email}</Text>
            <Text variant="bodyMBold">Language</Text>
            <Text variant="bodyMRegular">{user.lang}</Text>
            <Text variant="bodyMBold">Produits</Text>
            <Text variant="bodyMRegular">{user.sidebar_products.toString()}</Text>
        </Stack>
    );
};

const UserRow: FC<Props> = ({ user }: Props) => {
    return (
        <List.Row
            dataTrackId="admin_user_actions__user_selection"
            id={user.id}
            key={'user_row' + user.id}
            expandable={<UserSubRow user={user}></UserSubRow>}
        >
            <List.Cell>
                <Text variant="bodyMRegular">{user.first_name}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{user.last_name}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{user.role}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{user.provider}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{user.org_id}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">{user.status}</Text>
            </List.Cell>
            <List.Cell>
                <Text variant="bodyMRegular">
                    {user.can_use_bulk_modification ? 'true' : 'false'}
                </Text>
            </List.Cell>
            <List.Cell
                css={css`
                    align-items: flex-end;
                `}
            >
                <IconButton
                    icon={[FontAwesomeIconsPartooUsed.faAngleDown]}
                    dataTrackId="add"
                    appearance="outlined"
                />
            </List.Cell>
        </List.Row>
    );
};

export default UserRow;
