import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';

import { FEEDBACK_REPLY_SUGGESTION_PROMPT } from 'admin/common/data/queryKeysConstants';

export const useDeleteFeedbackReplySuggestionPrompt = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        ({ orgId }: { orgId: number }) => api.feedbackReplySuggestion.deletePrompt(orgId),
        {
            onSuccess: (_, { orgId }) => {
                queryClient.invalidateQueries([FEEDBACK_REPLY_SUGGESTION_PROMPT, orgId]);
                toast.success(
                    t('admin:feedback_management__delete_prompt_success_toast_content'),
                    t('admin:feedback_management__update_prompt_success_toast_title'),
                );
            },
            onError: () => {
                toast.error(
                    t('admin:feedback_management__delete_prompt_error_toast_content'),
                    t('admin:feedback_management__update_prompt_error_toast_title'),
                );
            },
        },
    );

    return mutate;
};
