import { SyntheticEvent, useEffect, useState } from 'react';

import { Button, Expandable, IconButton, IconPrefix, Stack, Text, TextArea } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ConfirmDeletePromptModal, ConfirmUpdatePromptModal } from './ConfirmEditPromptModal';
import { CustomReplySuggestionPromptChip } from './CustomReplySuggestionPromptChip';
import { StyledBorderedContainer, StyledPromptEditor } from './ReplySuggestionPromptEditor.styled';
import { useDeleteFeedbackReplySuggestionPrompt } from '../hooks/useDeleteFeedbackReplySuggestion';
import { useGetFeedbackReplySuggestionPrompt } from '../hooks/useGetFeedbackReplySuggestionPrompt';
import { useUpdateFeedbackReplySuggestionPrompt } from '../hooks/useUpdateFeedbackReplySuggestionPrompt';

type PromptEditorModalType = 'edit' | 'delete';

interface Props {
    orgId: number;
}

export const ReplySuggestionPromptEditor = ({ orgId }: Props) => {
    const { t } = useTranslation();

    const [openedModal, setOpenedModal] = useState<PromptEditorModalType | undefined>(undefined);
    const [opened, setOpened] = useState(false);
    const [prompt, setPrompt] = useState('');

    const [savedPrompt] = useGetFeedbackReplySuggestionPrompt(orgId, {
        enabled: !!orgId,
    });
    const updatePrompt = useUpdateFeedbackReplySuggestionPrompt();
    const deletePrompt = useDeleteFeedbackReplySuggestionPrompt();

    useEffect(() => {
        setPrompt(savedPrompt?.content ?? '');
    }, [savedPrompt]);

    const openModal = (type: PromptEditorModalType | undefined) => {
        setOpenedModal(type);
    };

    const resetPrompt = (e: SyntheticEvent) => {
        e.stopPropagation();
        setOpened(false);
        setPrompt(savedPrompt?.content ?? '');
    };

    return (
        <>
            <ConfirmUpdatePromptModal
                opened={openedModal === 'edit'}
                onConfirm={() => updatePrompt({ orgId, content: prompt })}
                onClose={() => openModal(undefined)}
            />
            <ConfirmDeletePromptModal
                opened={openedModal === 'delete'}
                onConfirm={() => deletePrompt({ orgId })}
                onClose={() => openModal(undefined)}
            />

            <StyledBorderedContainer onClick={() => setOpened(true)} opened={opened}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row">
                        <Text variant="bodyLSemibold">
                            {t('admin:feedback_management__reply_suggestion_prompt')}
                        </Text>
                        <CustomReplySuggestionPromptChip isCustomPrompt={savedPrompt?.is_custom} />
                    </Stack>
                    {!opened && (
                        <IconButton
                            dataTrackId={''}
                            appearance={'outlined'}
                            icon={['fa-pen-clip', IconPrefix.REGULAR]}
                            color="secondary"
                        />
                    )}
                </Stack>
                <Expandable opened={opened}>
                    <StyledPromptEditor>
                        <TextArea
                            dataTrackId="feedback_management__reply_suggestion_prompt"
                            value={prompt}
                            onChange={setPrompt}
                            maxRows={50}
                        />
                        <Stack direction="row" justifyContent="space-between">
                            <div>
                                {savedPrompt?.is_custom && (
                                    <Button
                                        dataTrackId="feedback_management__reply_suggestion_prompt_edit_reset"
                                        size="large"
                                        variant="danger"
                                        shape="cube"
                                        onClick={() => openModal('delete')}
                                    >
                                        {t('delete')}
                                    </Button>
                                )}
                            </div>
                            <Stack direction="row">
                                <Button
                                    dataTrackId="feedback_management__reply_suggestion_prompt_edit_cancel"
                                    size="large"
                                    variant="secondary"
                                    shape="cube"
                                    appearance="outlined"
                                    onClick={resetPrompt}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    dataTrackId="feedback_management__reply_suggestion_prompt_edit_save"
                                    size="large"
                                    variant="primary"
                                    shape="cube"
                                    onClick={() => openModal('edit')}
                                    disabled={!prompt || prompt === savedPrompt?.content}
                                >
                                    {t('save')}
                                </Button>
                            </Stack>
                        </Stack>
                    </StyledPromptEditor>
                </Expandable>
            </StyledBorderedContainer>
        </>
    );
};
