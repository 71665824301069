import { FC, useEffect, useState } from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { FontAwesomeIconsPartooUsed, IconPrefix, Option, Skeleton, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import {
    DiffusionCleanerAdminApiCall,
    DiffusionCleanerCountData,
} from 'admin/common/api/calls/diffusionCleanerApiCalls';

import { DeprecatedGroupSelect } from 'admin/common/components/inputs/DeprecatedGroupSelect';
import { LargeFilter } from 'admin/common/components/inputs/NewSelect.styled';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { NewProviderSelect } from 'admin/common/components/inputs/ProviderSelect/NewProviderSelect';
import AdminPage from 'admin/common/components/templates/AdminPage';
import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';
import { STATIC_BASE_URL } from 'admin/common/data/config';
import { DIFFUSION_CLEANER_COUNT } from 'admin/common/data/queryKeysConstants';
import { useNewGroups } from 'admin/common/hooks/queries/useNewGroups';
import { getSelectedOptions } from 'admin/common/utils/selectOptions';
import { EmptyPlaceholder } from 'admin/content/logs/common/components/List.styled';

import {
    DiffusionCleanerFiltersWrapper,
    DiffusionCleanerSmallFilter,
} from './components/DiffusionCleaner.styled';
import { DiffusionCleanerPublisherSelect } from './components/DiffusionCleanerPublisherSelect';
import { DiffusionErrorTypeSelect } from './components/DiffusionErrorTypeSelect';
import { DiffusionCleanerColumnHeadings } from './components/DiffusionSortColumn/DiffusionCleanerColumnHeadings';
import { ErrorCard } from './components/ErrorCard/ErrorCard';
import {
    DiffusionCleanerStartPageContainer,
    DiffusionCleanerStartPageContent,
    DiffusionErrorList,
} from './DiffusionCleaner.styled';
import GroupSelect from './GroupSelect';
import { useOrg } from './hooks/useOrg';

export const DiffusionCleaner: FC = () => {
    const { t } = useTranslation();
    const [provider, setProvider] = useState<Option | undefined>(undefined);
    const [org, setOrg] = useOrg();
    const [groups, setGroups] = useState<Record<string, boolean>>({});
    const [publishers, setPublishers] = useState<Record<string, boolean>>({});
    const [errorTypes, setErrorTypes] = useState<Record<string, boolean>>({});
    const [pageHasError, setPageHasError] = useState<boolean>(false);
    const [diffusionErrors, setDiffusionErrors] = useState<DiffusionCleanerCountData[]>([]);

    const orgId = org?.value;

    const enableNewGroups = useNewGroups(orgId ? parseInt(orgId) : undefined);

    const resetFilters = (new_provider?: Option) => {
        setProvider(new_provider);
        setOrg(undefined);
        setGroups({});
        setPublishers({});
        setErrorTypes({});
    };
    const handleOrgChange = (selectedOrg?: Option) => {
        setGroups({});
        setOrg(selectedOrg);
    };
    const arePrimaryFiltersSelected = org !== undefined || !!provider;

    const { data, isLoading } = useQuery(
        [DIFFUSION_CLEANER_COUNT, provider, org, groups, publishers, errorTypes],
        () =>
            DiffusionCleanerAdminApiCall.diffusionCleanerCount({
                orgId,
                provider: provider?.value,
                publishers: getSelectedOptions(publishers),
                errorKeys: getSelectedOptions(errorTypes),
                orderBy: '-count',
                ...(enableNewGroups
                    ? { groups: getSelectedOptions(groups) }
                    : { group__in: getSelectedOptions(groups) }),
            }),
        {
            staleTime: Infinity,
            onSuccess: () => {
                setPageHasError(false);
            },
            onError: () => {
                setPageHasError(true);
            },
            enabled: arePrimaryFiltersSelected,
        },
    );

    useEffect(() => {
        if (!isLoading) {
            setDiffusionErrors(data || []);
        }
    }, [data]);

    const illustrationElementSuccess: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.VeryHappySuccess,
        type: IllustrationCategoryEnum.Laptop,
    };
    const illustrationElementError: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Sad,
        type: IllustrationCategoryEnum.Laptop,
    };

    return (
        <AdminPage
            dataTrackId="page_diffusion_cleaner"
            title={t('admin:page_diffusion_cleaner__title')}
        >
            <DiffusionCleanerFiltersWrapper>
                <LargeFilter
                    data-track="admin_diffusion_cleaner__filter_provider"
                    data-intercom-target="admin_diffusion_cleaner__filter_provider"
                >
                    <NewProviderSelect
                        dataTrackId="admin_diffusion_cleaner__filter_provider"
                        provider={provider}
                        setProvider={resetFilters}
                    />
                </LargeFilter>
                <LargeFilter
                    data-track="admin_diffusion_cleaner__filter_organization"
                    data-intercom-target="admin_diffusion_cleaner__filter_organization"
                >
                    <NewOrganizationSelect
                        orgs={org}
                        setOrgs={handleOrgChange}
                        queryParams={{
                            provider: provider?.value,
                        }}
                        dataTrackId="admin_diffusion_cleaner__filter_organization"
                        placeholder={t('admin:organization')}
                    />
                </LargeFilter>
                <LargeFilter
                    data-track="admin_diffusion_cleaner__filter_group"
                    data-intercom-target="admin_diffusion_cleaner__filter_group"
                >
                    {enableNewGroups ? (
                        <GroupSelect
                            value={groups}
                            onChange={groups =>
                                setGroups(
                                    groups.reduce(
                                        (obj, value) => ({
                                            ...obj,
                                            [value]: true,
                                        }),
                                        {},
                                    ),
                                )
                            }
                            orgId={orgId ? parseInt(orgId) : undefined}
                        />
                    ) : (
                        <DeprecatedGroupSelect
                            dataTrackId="admin_diffusion_cleaner__filter_group"
                            groups={groups}
                            setGroups={setGroups}
                            disabled={org === undefined}
                            orgId={orgId}
                        />
                    )}
                </LargeFilter>
                <DiffusionCleanerSmallFilter>
                    <DiffusionCleanerPublisherSelect
                        publishers={publishers}
                        setPublishers={setPublishers}
                        disabled={!arePrimaryFiltersSelected}
                    />
                </DiffusionCleanerSmallFilter>
                <DiffusionCleanerSmallFilter>
                    <DiffusionErrorTypeSelect
                        errorTypes={errorTypes}
                        setErrorTypes={setErrorTypes}
                        disabled={!arePrimaryFiltersSelected}
                        provider={provider?.value}
                        orgId={orgId}
                        groups={getSelectedOptions(groups)}
                        publishers={getSelectedOptions(publishers)}
                    />
                </DiffusionCleanerSmallFilter>
            </DiffusionCleanerFiltersWrapper>
            <DiffusionCleanerColumnHeadings />
            {!isLoading && arePrimaryFiltersSelected && (
                <>
                    {!pageHasError && !!diffusionErrors?.length && (
                        <DiffusionErrorList role="list">
                            {diffusionErrors.map(diffusionError => (
                                <ErrorCard
                                    errorName={diffusionError.error_name}
                                    count={diffusionError.business_count}
                                    key={diffusionError.publisher + diffusionError.error_key}
                                    publisher={diffusionError.publisher}
                                    description={diffusionError.description}
                                    wikiLink={diffusionError.wiki_link}
                                    bulkButtons={diffusionError.bulk_buttons}
                                    businessButtons={diffusionError.business_buttons}
                                    provider={provider?.value}
                                    orgId={orgId}
                                    groups={getSelectedOptions(groups)}
                                    errorType={diffusionError.error_key}
                                    businessEditSection={diffusionError.business_edit_section}
                                />
                            ))}
                        </DiffusionErrorList>
                    )}
                    {!pageHasError && !diffusionErrors?.length && (
                        <EmptyPlaceholder>
                            <NotFoundTemplate
                                show
                                title={t('admin:page_diffusion_cleaner__empty_placeholder__title')}
                                imgSrc={illustrationElementSuccess}
                                withButton={false}
                            />
                        </EmptyPlaceholder>
                    )}
                    {pageHasError && (
                        <EmptyPlaceholder>
                            <NotFoundTemplate
                                show
                                title={t('admin:page_diffusion_cleaner__error_placeholder__title')}
                                imgSrc={illustrationElementError}
                                buttonText={t(
                                    'admin:page_diffusion_cleaner__error_placeholder__button',
                                )}
                                handleClick={() => window.location.reload()}
                                icon={[FontAwesomeIconsPartooUsed.faRotate, IconPrefix.SOLID]}
                            />
                        </EmptyPlaceholder>
                    )}
                </>
            )}
            {!isLoading && !arePrimaryFiltersSelected && (
                <DiffusionCleanerStartPageContainer>
                    <DiffusionCleanerStartPageContent>
                        <img
                            src={`${STATIC_BASE_URL}/images/app/diffusion_cleaner_start_page.svg`}
                            alt="no-result-found"
                        />
                        <Text as="span" variant="heading3">
                            {t('admin:page_diffusion_cleaner__start_page__title')}
                        </Text>
                    </DiffusionCleanerStartPageContent>
                </DiffusionCleanerStartPageContainer>
            )}
            {isLoading && <Skeleton col={2} />}
        </AdminPage>
    );
};
