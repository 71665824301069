import { useQuery } from 'react-query';

import { Organization } from 'admin/common/api/calls/orgApiCalls';
import { ORGANIZATIONS } from 'admin/common/data/queryKeysConstants';

import { useMe } from './useMe';

const useGroupsConfig = (orgId: number | string | null | undefined) => {
    return useQuery(
        [ORGANIZATIONS, { orgId }, 'groups_config'],
        () => Organization.getGroupsConfig(orgId ?? ''),
        {
            enabled: !!orgId,
        },
    );
};

export const useNewGroups = (orgId?: number): boolean | undefined => {
    const { data: me } = useMe();
    const { data: groupsConfig } = useGroupsConfig(orgId ?? me?.org_id);

    return groupsConfig?.switched_to_new_groups;
};
