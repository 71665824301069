import { useState } from 'react';

import { greyColorObject } from '@partoohub/branding';
import { Separator } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { UseMutateFunction } from 'react-query';

import { KeywordAdminData } from 'admin/common/api/types/competitiveBenchmark';

import { KeywordsSectionContainer } from './KeywordsSection.styled';
import { useOrgIdParsed } from '../../hooks/useOrgIdParsed';
import { updateKeywordStatus } from '../../utils/updateKeywordStatus';
import { ConfirmWarningModal } from '../ConfirmWarningModal/ConfirmWarningModal';
import { KeywordContent } from '../KeywordContent/KeywordContent';

import { KeywordTitle } from '../KeywordTitle/KeywordTitle';

type Props = {
    keywords: KeywordAdminData[];
    switchAction: UseMutateFunction<
        string[],
        unknown,
        {
            orgId: number;
            keyword: KeywordAdminData;
        },
        unknown
    >;
    action: () => void;
};

export const KeywordsSection = ({ keywords, switchAction, action }: Props) => {
    const { t } = useTranslation();
    const orgId = useOrgIdParsed();
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [targetedKeyword, setTargetedKeyword] = useState<KeywordAdminData | undefined>(
        keywords.find(keyword => keyword.active),
    );

    const handleToggleKeyword = (keywordName: string, active: boolean) => {
        const keyword = keywords.find(k => k.keyword === keywordName);
        setTargetedKeyword(keyword);

        if (active && keywords.find(k => k.active && k.id !== keyword?.id)) {
            setOpenWarningModal(true);
            return;
        }

        if (keyword) {
            updateKeywordStatus(orgId, keywords, keyword, switchAction);
        }
    };

    return (
        <>
            <KeywordsSectionContainer>
                <KeywordTitle action={action} />
                <Separator color={greyColorObject.initial} />
                {keywords.length > 0 &&
                    keywords.map((keyword, index) => {
                        return (
                            <KeywordContent
                                toggleAction={handleToggleKeyword}
                                key={`${keyword.keyword}-${index}`}
                                keyword={keyword}
                            />
                        );
                    })}
            </KeywordsSectionContainer>
            <ConfirmWarningModal
                opened={openWarningModal}
                title={t('admin:page_competitive_benchmark__modal_toggle_warning')}
                subTitle={t('admin:page_competitive_benchmark__modal_toggle_warning_sub_title')}
                onConfirm={() => {
                    if (targetedKeyword) {
                        updateKeywordStatus(orgId, keywords, targetedKeyword, switchAction);
                    }
                }}
                onClose={() => setOpenWarningModal(false)}
            />
        </>
    );
};
