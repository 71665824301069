import { FC, useEffect, useMemo } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconPrefix,
    LeftElementType,
    Loader,
    TextInput,
    TextInputProps,
} from '@partoohub/ui';
import { validate as isValidEmail } from 'email-validator';
import { Control, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';
import { FEEDBACK_MANAGEMENT_BASE_URL } from 'admin/common/data/config';

import { stringifyDate } from 'admin/common/utils/dates';
import { useOrg } from 'admin/content/tools/pages/DiffusionCleaner/hooks/useOrg';

import { useCreateOrUpdateFeedbackForm } from './hooks/useCreateOrUpdateFeedbackForm';
import { useGetFeedbackForm } from './hooks/useGetFeedbackForm';
import { ReplySuggestionPromptEditor } from './ReplySuggestionPromptEditor';

const DEFAULT_FORM_DATA = {
    email_reply_source: '',
    email_reply_subject: '',
    external_id: '',
    looker_studio_dashboard_link: '',
    expiration_interval_seconds: 1209600,
    display_name: '',
};

export const FeedbackManagement: FC = () => {
    const { t } = useTranslation();

    const [org, setOrg] = useOrg();
    const selectedOrgId =
        org?.value && typeof parseInt(org?.value, 10) === 'number' ? parseInt(org.value, 10) : 0;

    const [form, { isLoading: isLoadingFeedback }] = useGetFeedbackForm(selectedOrgId, {
        enabled: org !== undefined,
    });

    const {
        getValues,
        register,
        control,
        reset,
        setError,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: DEFAULT_FORM_DATA,
    });

    const required = t('field_required');
    register('email_reply_source', {
        required,
        validate: {
            email: v => isValidEmail(v) || t('email_invalid'),
        },
    });
    register('email_reply_subject', { required });
    register('external_id', { required });
    register('looker_studio_dashboard_link', { required: false });
    register('display_name', { required });

    const { createOrUpdateFeedbackForm, isCreatingOrUpdating } =
        useCreateOrUpdateFeedbackForm(setError);

    useEffect(() => {
        reset(Object.assign({}, DEFAULT_FORM_DATA, form ?? {}));
    }, [form]);

    const disableSaveButton = isCreatingOrUpdating || !selectedOrgId || !isValid;

    return (
        <AdminPage
            dataTrackId="page_tools_feedback_management__organization_settings"
            title={t('admin:side_menu_tools__section__feedback_management__organization_settings')}
        >
            <BorderedContainer>
                <PageFormRow>
                    <NewOrganizationSelect
                        dataTrackId="admin_feedback_management__select_org"
                        orgs={org}
                        setOrgs={setOrg}
                        placeholder={t('admin:feedback_management__select_org')}
                    />
                </PageFormRow>
                {isLoadingFeedback ? (
                    <Loader />
                ) : (
                    !!selectedOrgId && (
                        <>
                            <PageFormRow>
                                <ControlledTextInput
                                    dataTrackId="feedback_management__email"
                                    label={t('admin:feedback_management__email')}
                                    leftElementType={LeftElementType.Icon}
                                    leftElement={[
                                        FontAwesomeIconsPartooUsed.faEnvelope,
                                        IconPrefix.REGULAR,
                                    ]}
                                    name="email_reply_source"
                                    control={control}
                                    required
                                />
                                <ControlledTextInput
                                    dataTrackId="feedback_management__email_subject"
                                    label={t('admin:feedback_management__email_subject')}
                                    name="email_reply_subject"
                                    control={control}
                                    required
                                />
                            </PageFormRow>
                            <PageFormRow>
                                <ControlledTextInput
                                    dataTrackId="feedback_management__survey_id"
                                    label={t('admin:feedback_management__survey_id')}
                                    name="external_id"
                                    control={control}
                                    required
                                />
                                <ControlledTextInput
                                    dataTrackId="feedback_management__survey_name"
                                    label={t('admin:feedback_management__survey_name')}
                                    name="display_name"
                                    control={control}
                                    required
                                />
                            </PageFormRow>
                            <PageFormRow>
                                <ControlledTextInput
                                    dataTrackId="feedback_management__dashboard_link"
                                    label={t('admin:feedback_management__dashboard_link')}
                                    leftElementType={LeftElementType.Icon}
                                    leftElement={[
                                        FontAwesomeIconsPartooUsed.faLink,
                                        IconPrefix.REGULAR,
                                    ]}
                                    name="looker_studio_dashboard_link"
                                    control={control}
                                />
                            </PageFormRow>
                            {form?.id && (
                                <PageFormRow>
                                    <TextInput
                                        label={'feedback_management__survey_link'}
                                        value={`${FEEDBACK_MANAGEMENT_BASE_URL}/${form.id}?email=email&store_code=store_code&firstname=firstname&lastname=lastname&date=${stringifyDate(new Date())}`}
                                        type="text"
                                        copy={true}
                                        dataTrackId={'feedback_management__survey_link'}
                                        readOnly
                                    ></TextInput>
                                </PageFormRow>
                            )}
                            <PageFormRow>
                                <ReplySuggestionPromptEditor orgId={selectedOrgId} />
                            </PageFormRow>
                        </>
                    )
                )}
            </BorderedContainer>
            <PageFormRow>
                <SubmitButton
                    dataTrackId="feedback_management__submit"
                    disabled={disableSaveButton}
                    text={t('save')}
                    onClick={() => {
                        createOrUpdateFeedbackForm(form?.id, {
                            org_id: selectedOrgId,
                            ...getValues(),
                        });
                    }}
                    icon={[FontAwesomeIconsPartooUsed.faSave, IconPrefix.REGULAR]}
                />
            </PageFormRow>
        </AdminPage>
    );
};

type ControlledTextInputProps = TextInputProps<any> & {
    control: Control<any>;
    name: string;
};

const ControlledTextInput = ({ control, name, ...props }: ControlledTextInputProps) => {
    const {
        field,
        formState: { errors },
    } = useController({ control, name, defaultValue: '' });
    const Notice = useMemo(() => {
        // @ts-expect-error
        return <div>{errors[name]?.message}</div>;
    }, [errors[name]]);

    return <TextInput {...props} {...field} error={name in errors} notice={Notice} />;
};
