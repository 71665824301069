import { useEffect, useRef, useState } from 'react';

import { IconButton, IconPrefix, MenuList, Option } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ButtonWrapper, MenuListWrapper } from './MenuButton.styled';
import { MenuLabelItem } from './MenuLabelItem';

interface Props {
    onMenuClick: (value: string) => void;
}

export const MenuButton = ({ onMenuClick }: Props) => {
    const { t } = useTranslation();
    const wrapperRef = useRef<HTMLElement | null>(null);
    const [isMenuShown, setIsMenuShown] = useState(false);

    const options = [
        {
            name: 'edit',
            label: <MenuLabelItem text={t('edit')} icon={['fa-pen-alt', IconPrefix.REGULAR]} />,
            value: 'edit',
        },
        {
            name: 'delete',
            label: (
                <MenuLabelItem
                    text={t('delete')}
                    icon={['fa-trash-alt', IconPrefix.REGULAR]}
                    color="danger"
                />
            ),
            value: 'delete',
        },
    ];

    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (
                isMenuShown &&
                wrapperRef.current &&
                event.target instanceof Node &&
                !wrapperRef.current.contains(event.target)
            ) {
                toggleMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuShown]);

    const toggleMenu = () => {
        setIsMenuShown(prevIsMenuShown => !prevIsMenuShown);
    };

    const onClick = (value: Option) => {
        toggleMenu();
        onMenuClick(value.value);
    };

    return (
        <ButtonWrapper ref={ref => (wrapperRef.current = ref)}>
            <IconButton
                dataTrackId="service_menu__action_button"
                icon={['fa-ellipsis-v', IconPrefix.SOLID]}
                appearance="outlined"
                variant="default"
                onClick={toggleMenu}
                size="medium"
            />
            {isMenuShown && (
                <MenuListWrapper>
                    <MenuList
                        sections={[{ options }]}
                        isChecked={() => false}
                        onChange={value => {
                            if (value) onClick(value);
                        }}
                    />
                </MenuListWrapper>
            )}
        </ButtonWrapper>
    );
};
