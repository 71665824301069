import { isEqual, omit } from 'lodash-es';

import { FilteredBusinessData, V2FormattedBusinessData } from 'app/api/types/business';
import { ReviewBusinessFilter } from 'app/api/types/review';
import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { parseSearchParams } from 'app/common/components/businessModalDeprecated/parseSearchParams';
import businessPassesFilter from 'app/common/components/businessModalDeprecated/services/businessIsSelected';

import arrayPairExclusion from '../services/ArrayPairMethods';

export const SELECT_MODE = 'select';
export const UNSELECT_MODE = 'unselect';
export type FilterMode = 'select' | 'unselect';

// ACTION TYPES
const SHOW_FILTER_BY_BUSINESS_MODAL = 'SHOW_FILTER_BY_BUSINESS_MODAL';
export const HIDE_FILTER_BY_BUSINESS_MODAL = 'HIDE_FILTER_BY_BUSINESS_MODAL';
export const SET_FILTER_MODE = 'SET_FILTER_MODE';
const SET_INCLUDED_IDS_FILTER = 'SET_INCLUDED_IDS_FILTER';
export const ADD_FILTERED_BUSINESSES = 'ADD_FILTERED_BUSINESSES';
export const ADD_SEARCH_FILTER = 'ADD_SEARCH_FILTER';
const ADD_SEARCH_FILTER_SUCCESS = 'ADD_SEARCH_FILTER_SUCCESS';
const ADD_SEARCH_FILTER_FAILURE = 'ADD_SEARCH_FILTER_FAILURE';
export const APPLY_FILTER_BY_BUSINESS_FILTERS = 'APPLY_FILTER_BY_BUSINESS_FILTERS';
export const RESET_PENDING_BUSINESS_FILTER = 'RESET_PENDING_BUSINESS_FILTER';
const RESET_BUSINESS_FILTER = 'RESET_BUSINESS_FILTER';
export const RE_INIT_BUSINESS_FILTER = 'RE_INIT_BUSINESS_FILTER';

// BUSINESS MODAL SPECIFIC ACTIONS
const SET_BUSINESS_FILTERS = 'SET_BUSINESS_FILTERS';
const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';

// ACTION FLOW TYPES
type ShowFilterByBusinessAction = {
    type: 'SHOW_FILTER_BY_BUSINESS_MODAL';
};

type HideFilterByBusinessAction = {
    type: 'HIDE_FILTER_BY_BUSINESS_MODAL';
};

type SetFilterModeAction = {
    type: 'SET_FILTER_MODE';
    mode: FilterMode;
};

type SetIncludedIdsFilterAction = {
    type: 'SET_INCLUDED_IDS_FILTER';
    businessIds: Array<string>;
};

type AddFilteredBusinessesAction = {
    type: 'ADD_FILTERED_BUSINESSES';
    businessIds: Array<string>;
    shouldAdd: boolean;
};

export type AddSearchFilterAction = {
    type: 'ADD_SEARCH_FILTER';
    subqueries: Array<string>;
    cities: Array<string>;
    orgs: Array<string>;
    groupIdIn: Array<number>;
    groupIsNull: boolean | null;
};

type AddSearchFilterSuccessAction = {
    type: 'ADD_SEARCH_FILTER_SUCCESS';
    count: number;
    queries: Array<string>;
    cities: Array<string>;
    orgs: Array<string>;
    groupIdIn: Array<number>;
    groupIsNull: boolean | null;
};

type AddSearchFilterFailureAction = {
    type: 'ADD_SEARCH_FILTER_FAILURE';
    error: Record<string, any>;
};

type ResetBusinessFilterAction = {
    type: 'RESET_BUSINESS_FILTER';
};

type ReInitBusinessFilterAction = {
    type: 'RE_INIT_BUSINESS_FILTER';
};

// Specific to business modal
type SetBusinessFiltersAction = {
    type: typeof SET_BUSINESS_FILTERS;
    payload: Partial<FilteredBusinessData>;
};

type SetSearchFiltersAction = {
    type: typeof SET_SEARCH_FILTERS;
    searchFilters: BusinessSearchParams;
};

type ResetPendingBussinessFilterAction = {
    type: 'RESET_PENDING_BUSINESS_FILTER';
    name:
        | 'queries'
        | 'cities'
        | 'orgs'
        | 'groupId'
        | 'mode'
        | 'searchCount'
        | 'includedIds'
        | 'excludedIds'
        | 'all';
};

export type ApplyFilterByBusinessFiltersAction = {
    type: 'APPLY_FILTER_BY_BUSINESS_FILTERS';
};

type FilterByBusinessAction =
    | ShowFilterByBusinessAction
    | HideFilterByBusinessAction
    | SetFilterModeAction
    | SetIncludedIdsFilterAction
    | AddFilteredBusinessesAction
    | AddSearchFilterAction
    | AddSearchFilterSuccessAction
    | ResetPendingBussinessFilterAction
    | ApplyFilterByBusinessFiltersAction
    | AddSearchFilterFailureAction
    | ResetBusinessFilterAction
    | SetBusinessFiltersAction
    | SetSearchFiltersAction
    | ReInitBusinessFilterAction;

// Specific actions for business modal
export const setBusinessFilters = (
    payload: Partial<FilteredBusinessData>,
): SetBusinessFiltersAction => ({
    type: SET_BUSINESS_FILTERS,
    payload,
});

export const setSearchFilters = (searchFilters: BusinessSearchParams): SetSearchFiltersAction => ({
    type: SET_SEARCH_FILTERS,
    searchFilters,
});

// ACTION CREATORS
export const showFilterByBusiness = (): ShowFilterByBusinessAction => ({
    type: SHOW_FILTER_BY_BUSINESS_MODAL,
});

export const hideFilterByBusiness = (): HideFilterByBusinessAction => ({
    type: HIDE_FILTER_BY_BUSINESS_MODAL,
});

export const setFilterMode = (mode: FilterMode): SetFilterModeAction => ({
    type: SET_FILTER_MODE,
    mode,
});

export const addFilteredBusinesses = (
    businessIds: Array<string>,
    shouldAdd = true,
): AddFilteredBusinessesAction => ({
    type: ADD_FILTERED_BUSINESSES,
    businessIds,
    shouldAdd,
});

export const addSearchFilter = (
    subqueries: Array<string>,
    cities: Array<string>,
    orgs: Array<string>,
    groupIdIn: Array<number>,
    groupIsNull: boolean | null,
): AddSearchFilterAction => ({
    type: ADD_SEARCH_FILTER,
    subqueries,
    cities,
    orgs,
    groupIdIn,
    groupIsNull,
});

export const addSearchFilterSuccess = (
    count: number,
    queries: Array<string>,
    cities: Array<string>,
    orgs: Array<string>,
    groupIdIn: Array<number>,
    groupIsNull: boolean | null,
): AddSearchFilterSuccessAction => ({
    type: ADD_SEARCH_FILTER_SUCCESS,
    count,
    queries,
    cities,
    orgs,
    groupIdIn,
    groupIsNull,
});

export const addSearchFilterFailure = (
    error: Record<string, any>,
): AddSearchFilterFailureAction => ({
    type: ADD_SEARCH_FILTER_FAILURE,
    error,
});

export const applyFilterByBusinessFilters = (): ApplyFilterByBusinessFiltersAction => ({
    type: APPLY_FILTER_BY_BUSINESS_FILTERS,
});

export const resetPendingFilter = (
    name:
        | 'queries'
        | 'cities'
        | 'orgs'
        | 'mode'
        | 'searchCount'
        | 'includedIds'
        | 'excludedIds'
        | 'all',
): ResetPendingBussinessFilterAction => ({
    type: RESET_PENDING_BUSINESS_FILTER,
    name,
});

export const resetBusinessFilter = (): ResetBusinessFilterAction => ({
    type: RESET_BUSINESS_FILTER,
});

export const reInitBusinessFilter = (): ReInitBusinessFilterAction => ({
    type: RE_INIT_BUSINESS_FILTER,
});

export const setIncludedIdsFilter = (businessIds: Array<string>) => ({
    type: SET_INCLUDED_IDS_FILTER,
    businessIds,
});

// STATE FLOW TYPE
export type FilterByBusinessState = {
    filteredBusinesses: FilteredBusinessData & {
        pending: FilteredBusinessData;
    };
    searchFilters: BusinessSearchParams;
    displayModal: boolean;
    error: Record<string, any>;
};

const generateInitialState = (): FilterByBusinessState => {
    // the url search params here define the initial state,
    // if a search param is not present, it is parsed as its
    // corresponding empty value (for example [] for arrays)
    const { mode, includedIds, excludedIds, queries, cities, groupIdIn, orgs } = parseSearchParams(
        window.location,
    );

    return {
        filteredBusinesses: {
            mode,
            includedIds,
            excludedIds,
            queries,
            cities,
            orgs,
            groupIdIn,
            groupIsNull: true,
            searchCount: 0,
            pending: {
                mode,
                includedIds,
                excludedIds,
                queries,
                cities,
                orgs,
                groupIdIn,
                groupIsNull: true,
                searchCount: 0,
            },
        },
        searchFilters: {},
        displayModal: false,
        error: {},
    };
};

// INITIAL STATE
export const initialState: FilterByBusinessState = generateInitialState();

const resetState: FilterByBusinessState = {
    ...initialState,
    filteredBusinesses: {
        ...initialState.filteredBusinesses,
        mode: 'select',
        includedIds: [],
        excludedIds: [],
        queries: [],
        cities: [],
        orgs: [],
        groupIdIn: [],
        pending: {
            ...initialState.filteredBusinesses.pending,
            mode: 'select',
            includedIds: [],
            excludedIds: [],
            queries: [],
            cities: [],
            orgs: [],
            groupIdIn: [],
        },
    },
};

// REDUCER
const filterByBusinessReducer = (
    state: FilterByBusinessState = initialState,
    action: FilterByBusinessAction,
): FilterByBusinessState => {
    switch (action.type) {
        // (new) business modal Redux backport
        // this puts the local state from the modal into redux state (including pending)
        case SET_BUSINESS_FILTERS:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    ...action.payload,
                    pending: {
                        ...state.filteredBusinesses.pending,
                        ...action.payload,
                    },
                },
            };
        // Specific to the business modal
        // Set the page related business search filters
        case SET_SEARCH_FILTERS:
            return {
                ...state,
                searchFilters: action.searchFilters,
            };
        case SHOW_FILTER_BY_BUSINESS_MODAL:
            return { ...state, displayModal: true };

        case HIDE_FILTER_BY_BUSINESS_MODAL:
            return { ...state, displayModal: false };

        case SET_FILTER_MODE:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    pending: {
                        ...resetState.filteredBusinesses.pending,
                        mode: action.mode,
                    },
                },
            };

        case SET_INCLUDED_IDS_FILTER:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    pending: {
                        ...initialState.filteredBusinesses.pending,
                        includedIds: action.businessIds,
                    },
                },
            };

        case ADD_FILTERED_BUSINESSES: {
            const [includedIds, excludedIds] = arrayPairExclusion(
                state.filteredBusinesses.pending.includedIds,
                state.filteredBusinesses.pending.excludedIds,
                action.businessIds,
                action.shouldAdd,
            );
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    pending: {
                        ...state.filteredBusinesses.pending,
                        includedIds,
                        excludedIds,
                    },
                },
            };
        }

        case ADD_SEARCH_FILTER_SUCCESS:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    pending: {
                        ...state.filteredBusinesses.pending,
                        mode: SELECT_MODE,
                        // Remove this line to be able to EXCLUDE SEARCHES
                        queries: action.queries,
                        cities: action.cities,
                        orgs: action.orgs,
                        groupIdIn: action.groupIdIn,
                        groupIsNull: action.groupIsNull,
                        searchCount: action.count,
                        includedIds: [],
                        excludedIds: [],
                    },
                },
            };

        case APPLY_FILTER_BY_BUSINESS_FILTERS:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    ...state.filteredBusinesses.pending,
                },
            };

        case RESET_PENDING_BUSINESS_FILTER:
            return {
                ...state,
                filteredBusinesses: {
                    ...state.filteredBusinesses,
                    pending:
                        action.name !== 'all'
                            ? {
                                  ...state.filteredBusinesses.pending,
                                  [action.name]: resetState.filteredBusinesses[action.name],
                              }
                            : omit(state.filteredBusinesses, ['pending']),
                },
            };

        case ADD_SEARCH_FILTER_FAILURE:
            return { ...state, error: action.error };

        case RESET_BUSINESS_FILTER:
            return {
                ...state,
                filteredBusinesses: resetState.filteredBusinesses,
            };

        case RE_INIT_BUSINESS_FILTER:
            // this will read url, get params may have changed since
            // the initialState variable was create
            return generateInitialState();

        default:
            return state;
    }
};

// SELECTORS
export const groupIdInSelector = ({ filteredBusinesses: { groupIdIn } }: FilterByBusinessState) =>
    groupIdIn;

export const displayModalSelector = ({ displayModal }: FilterByBusinessState): boolean =>
    displayModal;

export const includedIdsSelector = ({
    filteredBusinesses: { includedIds },
}: FilterByBusinessState): string | null =>
    includedIds.length ? includedIds.join(',') || null : null;

export const excludedIdsSelector = ({
    filteredBusinesses: { excludedIds },
}: FilterByBusinessState): string | null =>
    excludedIds.length ? excludedIds.join(',') || null : null;

export const querySelector = ({
    filteredBusinesses: { queries },
}: FilterByBusinessState): string | null => queries.join(',') || null;

export const citySelector = ({
    filteredBusinesses: { cities },
}: FilterByBusinessState): string | null => cities.join(',') || null;

export const orgSelector = ({
    filteredBusinesses: { orgs, mode },
}: FilterByBusinessState): number | null => (mode === SELECT_MODE ? Number(orgs[0]) || null : null);

export const modeSelector = ({ filteredBusinesses: { mode } }: FilterByBusinessState): string =>
    mode;

export const queriesHaveChanged = (
    { filteredBusinesses: { pending: f } }: FilterByBusinessState,
    subqueries: Array<string>,
): boolean =>
    (f.mode === SELECT_MODE ? !!f.excludedIds.length : !!f.includedIds.length) ||
    !isEqual(subqueries, f.queries);

export const citiesHaveChanged = (
    { filteredBusinesses: { pending: f } }: FilterByBusinessState,
    cities: Array<string>,
): boolean =>
    (f.mode === SELECT_MODE ? !!f.excludedIds.length : !!f.includedIds.length) ||
    !isEqual(cities, f.cities);

export const groupsHaveChanged = (
    { filteredBusinesses: { pending: f } }: FilterByBusinessState,
    groups: Array<number>,
): boolean =>
    (f.mode === SELECT_MODE ? !!f.excludedIds.length : !!f.includedIds.length) ||
    !isEqual(groups, f.groupIdIn);

export const orgsHaveChanged = (
    { filteredBusinesses: { pending: f } }: FilterByBusinessState,
    orgs: Array<string>,
): boolean =>
    (f.mode === SELECT_MODE ? f.excludedIds.length > 0 : f.includedIds.length > 0) ||
    !isEqual(orgs, f.orgs);

export const selectedPendingBusinessCount = (
    { filteredBusinesses: { pending: f } }: FilterByBusinessState,
    totalBusinessCount: number,
): number =>
    f.includedIds.length -
    f.excludedIds.length +
    (f.mode === SELECT_MODE ? f.searchCount : totalBusinessCount - f.searchCount);

export const isBusinessSelected = (
    { filteredBusinesses: { pending: f } }: FilterByBusinessState,
    business: V2FormattedBusinessData,
): boolean =>
    ((f.mode === SELECT_MODE) ===
        businessPassesFilter(business, f.queries, f.cities, f.orgs, f.groupIdIn) ||
        f.includedIds.includes(business.id)) &&
    !f.excludedIds.includes(business.id);

export const activeBusinessSelectionSelector = (
    state: FilterByBusinessState,
): Record<keyof ReviewBusinessFilter, any> => {
    const includedIds = includedIdsSelector(state);
    const excludedIds = excludedIdsSelector(state);
    const businessFilter: any = {};
    if (includedIds) businessFilter.includedIds = includedIds.split(',');
    if (excludedIds) businessFilter.excludedIds = excludedIds.split(',');
    return businessFilter;
};

export const pendingFilteredBusinessesSelector = ({
    filteredBusinesses: f,
}: FilterByBusinessState) => f.pending;

export const pendingGroupIdInSelector = ({ filteredBusinesses: f }: FilterByBusinessState) =>
    f.pending.groupIdIn;

export const currentFilteredBusinessesSelector = ({
    filteredBusinesses: f,
}: FilterByBusinessState) => omit(f, ['pending']);

export default filterByBusinessReducer;
