import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

import BorderedContainer from 'admin/common/components/layout/BorderedContainer';

export const StyledBorderedContainer = styled(BorderedContainer)<{ opened: boolean }>`
    padding: 24px;
    ${({ opened, theme }) =>
        !opened
            ? css`
                  cursor: pointer;
              `
            : css`
                  border: 1px solid ${theme.colors.primary.initial};
                  box-shadow: ${theme.colors.primary.initial} 0 0 0 1px;
              `}
`;

export const StyledPromptEditor = styled(Stack)`
    margin-top: 16px;
`;
