import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useIsSideMenuOpen } from 'admin/common/contexts/isSideMenuOpenContext';
import {
    EXPORTER_PATH,
    GROUPS_MIGRATION_PATH,
    IMAGE_UPLOADER_PATH,
    IMPORTER_PATH,
    MENU_CREATION_PATH,
    MESSAGES_EXPORTER_PATH,
    MESSAGES_IMPORTER_PATH,
    OLD_EXPORTER_PATH,
    OLD_IMPORTER_PATH,
    SERVICE_CREATION_PATH,
    TRIPADVISOR_IMPORTER_PATH,
} from 'admin/common/data/routeIds';

import { SideMenuContent, SideMenuTitle, SideMenuWrapper } from './Menus.styled';
import MenuLink from '../MenuLink';

import Sublink from '../Sublink';

const DataMenu: FC = () => {
    const { t } = useTranslation();
    const { isSideMenuOpen } = useIsSideMenuOpen();

    return (
        <>
            <SideMenuWrapper open={isSideMenuOpen}>
                <SideMenuTitle>{t('admin:side_menu_data__title')}</SideMenuTitle>

                <SideMenuContent>
                    <MenuLink
                        to={EXPORTER_PATH}
                        name={t('admin:side_menu_data__section_export')}
                        relatedPaths={[EXPORTER_PATH, OLD_EXPORTER_PATH, MESSAGES_EXPORTER_PATH]}
                    >
                        <Sublink
                            to={EXPORTER_PATH}
                            name={t('admin:side_menu_data__section_export__export')}
                        />
                        <Sublink
                            to={OLD_EXPORTER_PATH}
                            name={t('admin:side_menu_data__section_export__export_old')}
                        />
                        <Sublink
                            to={MESSAGES_EXPORTER_PATH}
                            name={t('admin:side_menu_data__section_export__messages')}
                        />
                    </MenuLink>
                    <MenuLink
                        to={IMPORTER_PATH}
                        name={t('admin:side_menu_data__section_import')}
                        relatedPaths={[
                            IMPORTER_PATH,
                            OLD_IMPORTER_PATH,
                            MESSAGES_IMPORTER_PATH,
                            TRIPADVISOR_IMPORTER_PATH,
                        ]}
                    >
                        <Sublink
                            to={IMPORTER_PATH}
                            name={t('admin:side_menu_data__section_import__import')}
                        />
                        <Sublink
                            to={OLD_IMPORTER_PATH}
                            name={t('admin:side_menu_data__section_import__import_old')}
                        />
                        <Sublink
                            to={MESSAGES_IMPORTER_PATH}
                            name={t('admin:side_menu_data__section_import__import_messages')}
                        />
                        <Sublink
                            to={TRIPADVISOR_IMPORTER_PATH}
                            name={t('admin:side_menu_data__section_import__import_tripadvisor')}
                        />
                    </MenuLink>
                    <MenuLink
                        to={SERVICE_CREATION_PATH}
                        name={t('admin:side_menu_bulk__title')}
                        relatedPaths={[
                            SERVICE_CREATION_PATH,
                            MENU_CREATION_PATH,
                            GROUPS_MIGRATION_PATH,
                        ]}
                    >
                        <Sublink
                            to={SERVICE_CREATION_PATH}
                            name={t('admin:side_menu_bulk__section_bulk__services')}
                        />
                        <Sublink
                            to={MENU_CREATION_PATH}
                            name={t('admin:side_menu_bulk__section_bulk__food_menus')}
                        />
                        <Sublink to={GROUPS_MIGRATION_PATH} name="Groups Migration" />
                    </MenuLink>
                    <MenuLink
                        to={IMAGE_UPLOADER_PATH}
                        name={t('admin:side_menu_data__section_misc')}
                        relatedPaths={[IMAGE_UPLOADER_PATH]}
                    >
                        <Sublink
                            to={IMAGE_UPLOADER_PATH}
                            name={t('admin:side_menu_bulk__section_misc__image_uploader')}
                        />
                    </MenuLink>
                </SideMenuContent>
            </SideMenuWrapper>
            <Outlet />
        </>
    );
};

export default DataMenu;
