import React, { useEffect } from 'react';

import { Drawer } from '@partoohub/ui';
import { useDispatch } from 'react-redux';

import { BusinessModalProvider } from 'app/common/components/businessModal/context/BusinessModalContext';
import BusinessModalGetParamsReduxSync from 'app/common/components/businessModal/getParamsReduxSync/BusinessModalGetParamsReduxSync';
import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import {
    resetBusinessFilter,
    setSearchFilters,
} from 'app/common/components/businessModalDeprecated/reducers';
import { useFilteredCities } from 'app/common/hooks/queries/useCities';
import { useGroups } from 'app/common/hooks/queries/useGroups';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import usePrevious from 'app/common/hooks/usePrevious';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';
import { useHideIntercom } from 'app/hooks/useHideIntercom';

import { BUSINESS_MODAL_DRAWER_CSS } from './BusinessModal.styled';
import BusinessModalContent from './BusinessModalContent';

export type ModalVariation = 'group-only' | 'business-only' | 'all';

export type BusinessModalContextProps = {
    // Variation of the modal, to display only groups, only businesses or both
    variation?: ModalVariation;

    // Remove the 50 business limit, will remove the url sync and MUST not be used as url query params
    noBusinessLimit?: boolean;

    // If true, the modal will be in selection mode, and won't consider all business selected if no filter
    // Display only, must be used with BusinessSearchCompanion in selection mode in the backend as well
    isSelectionMode?: boolean;
};

export type BusinessModalProps = BusinessModalContextProps & {
    withUrlSync?: boolean;
    // Business Search filters outside the normal modal filters, set by the page
    searchFilters?: BusinessSearchParams;
    resetReducerOnUnmount?: boolean;
};

type Props = BusinessModalProps & {
    opened: boolean;
    closeModal: () => void;
};

/** Main business modal component.
 *  Use it if you need a controlled opened state, for example if you need to open
 *  the Business Modal programmatically.
 *
 *  If you simply need the usual button, use BusinessModalButton which already
 *  contains the business modal.
 *
 *  If you simply need a custom button, you can provide a button prop to BusinessModalButton
 *
 *  @example
 *  ```
 *  const BusinessModalButtonVariant = () => {
 *      const [opened, setIsOpened] = useState<boolean>(false)
 *
 *      return (
 *         <>
 *             <ButtonVariant onClick={() => setIsOpened(true)}/>
 *             <BusinessModal opened={opened} closeModal{() => setIsOpened(false)} />
 *         </>
 *      )
 *  }
 *  ```
 */
const BusinessModal = ({
    opened,
    closeModal,
    searchFilters,
    withUrlSync = true,
    resetReducerOnUnmount = true,
    noBusinessLimit,
    variation,
    isSelectionMode = false,
}: Props) => {
    const enableNewGroups = useNewGroups();
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);

    // trigger those two queries when the modal is mounted on the page
    // so that the data is already there when the user opens it
    useGroups({}, { enabled: !enableNewGroups });
    useFilteredCities();

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (resetReducerOnUnmount) {
                dispatch(resetBusinessFilter());
            }
        };
    }, [resetReducerOnUnmount]);

    // searchFilters might change ref between renders, so we need to compare the content
    // This is a security to avoid infinite loop
    const previousSearchFiltersStringify = usePrevious(JSON.stringify(searchFilters));

    // Be careful not to put the {} as a default value here for searchFilters, as it will cause an infinite loop
    useEffect(() => {
        if (JSON.stringify(searchFilters) !== previousSearchFiltersStringify) {
            dispatch(setSearchFilters(searchFilters ?? {}));
        }
    }, [searchFilters, previousSearchFiltersStringify]);

    // Hide intercom when drawer is open
    useHideIntercom(opened);

    const modalVariation: ModalVariation = enableNewGroups ? (variation ?? 'all') : 'business-only';

    return (
        <>
            {withUrlSync && !noBusinessLimit && <BusinessModalGetParamsReduxSync />}
            <BusinessModalProvider
                noBusinessLimit={!!noBusinessLimit}
                variation={modalVariation}
                isSelectionMode={isSelectionMode}
            >
                <Drawer
                    dataTrackId="business-modal"
                    isOpen={opened}
                    onHide={closeModal}
                    cssContainer={BUSINESS_MODAL_DRAWER_CSS}
                    isFullWidth={isBelowProvidedDevices}
                >
                    <BusinessModalContent closeModal={closeModal} />
                </Drawer>
            </BusinessModalProvider>
        </>
    );
};

export default BusinessModal;
