import React, { FC, useState } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useMe } from 'admin/common/hooks/queries/useMe';

import env from 'admin/common/utils/getEnvironment';

import FoodMenusCreation from 'admin/content/data/pages/FoodMenusCreation';

import ReviewCleaner from 'admin/content/playground/pages/ReviewManagement';

import { UserData } from './common/api/types/user';
import Loader from './common/components/loader/Loader';
import NotFoundPage from './common/components/navigation/NotFoundPage';
import {
    CategoriesMenu,
    ConnectAsMenu,
    DataMenu,
    LogsMenu,
    ToolsMenu,
} from './common/components/navigation/SideMenu';
import PlaygroundMenu from './common/components/navigation/SideMenu/Menus/PlaygroundMenu';
import UnauthorizedPage from './common/components/navigation/UnauthorizedPage';
import AppTemplate from './common/components/templates';
import { ADMIN } from './common/data/constants/roles';
import {
    BULK_FOOD_MENUS,
    BULK_GROUP_MIGRATION,
    BULK_SERVICES,
    CATEGORIES,
    CATEGORIES_EDIT,
    CATEGORIES_REMAP,
    CONNECTION_PATH,
    CONNECT_AS,
    CONNECT_AS_PATH,
    DAILY_REPORTS,
    DATA,
    DATA_EXPORTER,
    DATA_IMPORTER,
    DATA_OLD_EXPORTER,
    DATA_OLD_IMPORTER,
    DIFFUSION_LOGS_PATH,
    EXPORTER_PATH,
    GENERIC_PROMPT,
    IMAGE_UPLOADER,
    IMPORT_LOGS,
    LOGS,
    LOGS_DIFFUSION,
    MESSAGES_EXPORTER_PATH,
    MESSAGES_IMPORTER_PATH,
    ORGANIZATION_SETTINGS,
    OVERRIDING_TOOL_PATH,
    PLAYGROUND,
    PLAYGROUND_CALL_DEFLECTION,
    PLAYGROUND_GOOGLE_ACCOUNT_REFRESH,
    PLAYGROUND_GOOGLE_ADMIN_MANAGEMENT,
    PLAYGROUND_REVIEW_CLEANER,
    PLAYGROUND_USER_ACTIONS,
    PROSPECTS_LOCATION_SEARCH_TOOL_PATH,
    SEARCH_LOCATION_CLIENTS,
    SEARCH_LOCATION_PROSPECTS,
    SERVICE_CREATION_PATH,
    TOOLS,
    TOOLS_COMPETITIVE_BENCHMARK,
    TOOLS_DEMO_CREATOR,
    TOOLS_DIFFUSION_CLEANER,
    TOOLS_FEEDBACK_MANAGEMENT,
    TOOLS_GENERATE_FAKE_DATA,
    TOOLS_OVERRIDING,
    TOOLS_REVIEW,
    TOOLS_SEARCH_LOCATION,
    TRIPADVISOR_IMPORTER_PATH,
    UNAUTHORIZED_PATH,
    USER_ACTIONS_PATH,
} from './common/data/routeIds';
import { setUser } from './common/utils/connection';
import CategoryEdit from './content/categories/pages/CategoryEdit';
import CategoryList from './content/categories/pages/CategoryList';
import RemapCategory from './content/categories/pages/CategoryRemap';
import ConnectAs from './content/connectAs/pages/ConnectAs';
import Connection from './content/connection';
import Exporter from './content/data/pages/Exporter';
import ExporterV1 from './content/data/pages/ExporterV1';
import GroupMigration from './content/data/pages/GroupMigration/GroupMigration';
import ImageUploader from './content/data/pages/ImageUploader';
import Importer from './content/data/pages/Importer';
import ImporterV1 from './content/data/pages/ImporterV1';
import MessagingExporter from './content/data/pages/MessagingExporter';
import MessagingImporter from './content/data/pages/MessagingImporter';
import ServicesCreation from './content/data/pages/ServicesCreation';
import TripAdvisorUploader from './content/data/pages/TripAdvisorImporter';
import DailyReport from './content/logs/pages/DailyReport';
import DiffusionLogs from './content/logs/pages/DiffusionLogs';
import ImportLog from './content/logs/pages/ImportLog';
import CallDeflectionAudioUpload from './content/playground/pages/CallDeflectionAudioUpload';
import GoogleAccountRefresh from './content/playground/pages/GoogleAccountRefresh';
import GoogleAdminManagement from './content/playground/pages/GoogleAdminManagement';
import { UserActions } from './content/playground/pages/UserActions/UserActions';
import CompetitiveBenchmark from './content/tools/pages/CompetitiveBenchmark';
import DemoCreator from './content/tools/pages/DemoCreator';
import DiffusionCleaner from './content/tools/pages/DiffusionCleaner';
import DuplicateClientSearch from './content/tools/pages/DuplicateBusinessSearch';
import FeedbackManagement from './content/tools/pages/FeedbackManagement';
import GenericPrompt from './content/tools/pages/FeedbackManagement/GenericPrompt';
import GenerateFakeData from './content/tools/pages/GenerateFakeData';
import OverridingState from './content/tools/pages/OverridingState';
import ProspectListing from './content/tools/pages/ProspectListing';
import ReviewTreatment from './content/tools/pages/ReviewTreatment';

/** App
 *
 * React-Router v6 allows nesting <Route/> elements, so
 * all app routes can be defined in one file.
 */
const App: FC = () => {
    const [appLoaded, setAppLoaded] = useState<boolean>(false);
    const shouldIgnoreRedirection =
        window.location.pathname === CONNECTION_PATH ||
        window.location.pathname === UNAUTHORIZED_PATH;

    useMe({
        onSuccess: (data: UserData) => {
            if (shouldIgnoreRedirection) return;

            if (data.role !== ADMIN) window.location.href = UNAUTHORIZED_PATH;
            else {
                setUser(data);
                setAppLoaded(true);
            }
        },
        onError: () => {
            if (shouldIgnoreRedirection) return;

            window.location.href = `${CONNECTION_PATH}?next_page=${window.location.pathname}`;
        },
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    return (
        <Routes>
            <Route path={CONNECTION_PATH} element={<Connection />} />
            <Route path={UNAUTHORIZED_PATH} element={<UnauthorizedPage />} />
            {appLoaded && (
                <Route path="/" element={<AppTemplate />}>
                    <Route index element={<Navigate to={CONNECT_AS_PATH} />} />
                    <Route path={CONNECT_AS} element={<ConnectAsMenu />}>
                        <Route path="" element={<ConnectAs />} />
                    </Route>

                    <Route path={DATA} element={<DataMenu />}>
                        <Route index element={<Navigate to={EXPORTER_PATH} />} />
                        <Route path={DATA_EXPORTER} element={<Exporter />} />
                        <Route path={DATA_OLD_EXPORTER} element={<ExporterV1 />} />
                        <Route path={MESSAGES_EXPORTER_PATH} element={<MessagingExporter />} />
                        <Route path={DATA_IMPORTER} element={<Importer />} />
                        <Route path={DATA_OLD_IMPORTER} element={<ImporterV1 />} />
                        <Route path={MESSAGES_IMPORTER_PATH} element={<MessagingImporter />} />
                        <Route path={TRIPADVISOR_IMPORTER_PATH} element={<TripAdvisorUploader />} />
                        <Route index element={<Navigate to={SERVICE_CREATION_PATH} />} />
                        <Route path={BULK_SERVICES} element={<ServicesCreation />} />
                        <Route path={BULK_FOOD_MENUS} element={<FoodMenusCreation />} />
                        <Route path={BULK_GROUP_MIGRATION} element={<GroupMigration />} />
                        <Route path={IMAGE_UPLOADER} element={<ImageUploader />} />
                    </Route>
                    <Route path={LOGS} element={<LogsMenu />}>
                        <Route index element={<Navigate to={DIFFUSION_LOGS_PATH} />} />
                        <Route path={LOGS_DIFFUSION} element={<DiffusionLogs />} />
                        <Route path={IMPORT_LOGS} element={<ImportLog />} />
                        <Route path={DAILY_REPORTS} element={<DailyReport />} />
                    </Route>

                    <Route path={CATEGORIES} element={<CategoriesMenu />}>
                        <Route index element={<CategoryList />} />
                        <Route path={`${CATEGORIES_EDIT}/:categoryId`} element={<CategoryEdit />} />
                        <Route
                            path={`${CATEGORIES_REMAP}/:categoryId`}
                            element={<RemapCategory />}
                        />
                    </Route>

                    <Route path={TOOLS} element={<ToolsMenu />}>
                        <Route index element={<Navigate to={OVERRIDING_TOOL_PATH} />} />
                        <Route path={TOOLS_OVERRIDING} element={<OverridingState />} />
                        <Route path={TOOLS_REVIEW} element={<ReviewTreatment />} />
                        <Route path={TOOLS_SEARCH_LOCATION}>
                            <Route
                                index
                                element={<Navigate to={PROSPECTS_LOCATION_SEARCH_TOOL_PATH} />}
                            />
                            <Route path={SEARCH_LOCATION_PROSPECTS} element={<ProspectListing />} />
                            <Route
                                path={SEARCH_LOCATION_CLIENTS}
                                element={<DuplicateClientSearch />}
                            />
                        </Route>
                        {!env.isProd() && (
                            <Route path={TOOLS_GENERATE_FAKE_DATA} element={<GenerateFakeData />} />
                        )}
                        {!env.isProd() && !env.isPreprod() && (
                            <Route path={TOOLS_DEMO_CREATOR} element={<DemoCreator />} />
                        )}
                        <Route path={TOOLS_DIFFUSION_CLEANER} element={<DiffusionCleaner />} />
                        <Route path={TOOLS_FEEDBACK_MANAGEMENT}>
                            <Route path={ORGANIZATION_SETTINGS} element={<FeedbackManagement />} />
                            <Route path={GENERIC_PROMPT} element={<GenericPrompt />} />
                        </Route>
                        <Route
                            path={TOOLS_COMPETITIVE_BENCHMARK}
                            element={<CompetitiveBenchmark />}
                        />
                    </Route>

                    <Route path={PLAYGROUND} element={<PlaygroundMenu />}>
                        <Route index element={<Navigate to={USER_ACTIONS_PATH} />} />
                        <Route path={PLAYGROUND_USER_ACTIONS} element={<UserActions />} />
                        <Route
                            path={PLAYGROUND_GOOGLE_ACCOUNT_REFRESH}
                            element={<GoogleAccountRefresh />}
                        />
                        <Route path={PLAYGROUND_REVIEW_CLEANER} element={<ReviewCleaner />} />
                        <Route
                            path={PLAYGROUND_CALL_DEFLECTION}
                            element={<CallDeflectionAudioUpload />}
                        />
                        <Route
                            path={PLAYGROUND_GOOGLE_ADMIN_MANAGEMENT}
                            element={<GoogleAdminManagement />}
                        />
                    </Route>

                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            )}
            {!appLoaded && <Route path="*" element={<Loader />} />}
        </Routes>
    );
};
export default React.memo(App);
