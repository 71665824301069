export const AUDIO_TYPES = {
    'audio/*': [
        '.mp3',
        '.wma',
        '.wmv',
        '.flac',
        '.wav',
        '.ogg',
        '.aac',
        '.m4a',
        '.mp4',
        '.mov',
        '.mkv',
    ],
};
