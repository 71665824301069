import { FC, useEffect, useState } from 'react';

import { MultiSelect, Option, Section, SingleSelect, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { SearchOrgParams } from 'app/api/types/org';

import { useOrgs } from 'admin/common/hooks/queries/useOrgs';

import { convertOrgToSelectOption } from './convertOrgToOption';
import { EmptyMessageWrapper } from '../EmptyMessage';

type CommonProps = {
    dataTrackId: string;
    placeholder?: string;
    isMulti?: boolean;
    required?: boolean;
    queryParams?: SearchOrgParams;
};

type MultiSelectProps = {
    orgs: Array<Option>;
    setOrgs: (selectedItems: Option[]) => void;
} & CommonProps;

type SingleSelectProps = {
    orgs?: Option;
    setOrgs: (selectedItems?: Option) => void;
} & CommonProps;

type Props = MultiSelectProps | SingleSelectProps;

export const NewOrganizationSelect: FC<Props> = ({
    dataTrackId,
    placeholder = 'Organization',
    isMulti = false,
    required = false,
    orgs,
    setOrgs,
    queryParams = {},
}: Props) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [section, setSection] = useState<Section[]>([]);

    const queryParamsOrgSearch: SearchOrgParams = {
        query: searchValue,
        ...queryParams,
    };
    const [{ data, fetchNextPage, hasNextPage, isLoading }] = useOrgs(queryParamsOrgSearch, {
        keepPreviousData: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
    });

    const organizations = data?.pages?.map(({ orgs }) => orgs)?.flat();

    useEffect(() => {
        if (!isLoading) {
            const options =
                organizations
                    ?.flat()
                    ?.map(organization => convertOrgToSelectOption(organization)) || [];
            const section = [{ options: options }];
            setSection(section);
        }
    }, [data]);

    const handleMultiSelectChange = (selectedOptions: Option[]): void => {
        (setOrgs as (orgs: Option[]) => void)(selectedOptions);
    };

    const handleSingleSelectChange = (selectedOption?: Option): void => {
        (setOrgs as (org?: Option) => void)(selectedOption);
    };

    return (
        <>
            {isMulti ? (
                <MultiSelect
                    dataTrackId={dataTrackId}
                    label={placeholder}
                    sections={section}
                    placeholder=""
                    selectedValue={orgs as Option[]}
                    onChange={handleMultiSelectChange}
                    onSearch={setSearchValue}
                    maxHeight={300}
                    emptyMessage={
                        <EmptyMessageWrapper>
                            <Text variant="bodyMMedium">
                                {t('admin:select_organization__empty_message')} “{searchValue}“
                            </Text>
                        </EmptyMessageWrapper>
                    }
                    required={required}
                    loadMore={fetchNextPage}
                    hasMore={hasNextPage}
                />
            ) : (
                <SingleSelect
                    dataTrackId={dataTrackId}
                    label={placeholder}
                    sections={section}
                    placeholder=""
                    // @ts-expect-error
                    selectedValue={orgs}
                    onChange={handleSingleSelectChange}
                    onSearch={setSearchValue}
                    maxHeight={300}
                    emptyMessage={
                        <EmptyMessageWrapper>
                            <Text variant="bodyMMedium">
                                {t('admin:select_organization__empty_message')} “{searchValue}“
                            </Text>
                        </EmptyMessageWrapper>
                    }
                    required={required}
                    loadMore={fetchNextPage}
                    hasMore={hasNextPage}
                />
            )}
        </>
    );
};
