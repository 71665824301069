import React, { useState } from 'react';

import { Filters, Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { DiffusionCleanerAdminApiCall } from 'admin/common/api/calls/diffusionCleanerApiCalls';
import { DIFFUSION_CLEANER_COUNT } from 'admin/common/data/queryKeysConstants';
import { useNewGroups } from 'admin/common/hooks/queries/useNewGroups';

type Props = {
    errorTypes: Record<string, boolean>;
    setErrorTypes: (selectedItems: Record<string, boolean>) => void;
    disabled?: boolean;
    provider: string | undefined;
    orgId: string | undefined;
    publishers: string[] | undefined;
    groups: string[] | undefined;
};

export const DiffusionErrorTypeSelect: React.FC<Props> = ({
    errorTypes,
    setErrorTypes,
    disabled,
    provider,
    orgId,
    publishers,
    groups,
}: Props) => {
    const { t } = useTranslation();
    const [pageHasError, setPageHasError] = useState<boolean>(false);
    const enableNewGroups = useNewGroups(orgId ? parseInt(orgId) : undefined);

    const { data, isLoading } = useQuery(
        [DIFFUSION_CLEANER_COUNT, provider, orgId, groups, publishers, errorTypes],
        () =>
            DiffusionCleanerAdminApiCall.diffusionCleanerCount({
                orgId: orgId,
                provider: provider,
                publishers: publishers,
                ...(enableNewGroups ? { groups: groups } : { group__in: groups }),
            }),
        {
            staleTime: Infinity,
            onSuccess: () => {
                setPageHasError(false);
            },
            onError: () => {
                setPageHasError(true);
            },
            enabled: !!orgId?.length || !!provider,
        },
    );

    const diffusionCleanerErrorTypes: Option[] =
        data && !pageHasError && !isLoading
            ? data.map(error => ({
                  label: t(`admin:${error.error_name}`),
                  name: error.error_key,
                  value: error.error_key,
              }))
            : [];

    const uniqueDiffusionCleanerErrorTypes = [
        ...new Map(diffusionCleanerErrorTypes.map(item => [item.name, item])).values(),
    ];
    const section = [{ options: uniqueDiffusionCleanerErrorTypes }];

    const hasErrorToDisplay: boolean = uniqueDiffusionCleanerErrorTypes.length !== 0;
    return (
        <Filters
            dataTrackId="admin_diffusion_cleaner__filter_error_type"
            menuListArray={section}
            selectedItems={errorTypes}
            onChange={setErrorTypes}
            placeholder={
                hasErrorToDisplay
                    ? t('admin:page_diffusion_cleaner__error_type_filter')
                    : t('admin:page_diffusion_cleaner__no_error_type_filter')
            }
            position="bottom"
            maxHeight={300}
            multiple={true}
            disabled={disabled || !hasErrorToDisplay}
        />
    );
};
