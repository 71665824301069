import { SuccessResponse } from 'app/api/types';
import AdminApiResourceBaseClass from 'app/api/v2/api_calls/baseAdminApiCalls';

const RESOURCE_URI = 'messaging_extractor';

type MessagingExportAdminFilter = {
    email: string;
    org_id: string;
    provider: string;
    status: GoogleAgentStatusChoices;
};

export enum GoogleAgentStatusChoices {
    NO_AGENTS = 'NO_AGENTS',
    NOT_LAUNCHED = 'NOT_LAUNCHED',
    LAUNCHED = 'LAUNCHED',
}

class MessagingExportAdminApiCall extends AdminApiResourceBaseClass {
    static bulkExport(
        filter: Partial<MessagingExportAdminFilter>,
    ): Promise<SuccessResponse> {
        return MessagingExportAdminApiCall.get(`${RESOURCE_URI}`, {
            ...filter,
        }).then(({ data }) => data);
    }
}

export default MessagingExportAdminApiCall;
