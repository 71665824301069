const extractPartooSubdomain = () => {
    const domainPattern = /admin\.([^.]+)\.partoo\.co/i;
    const match = window.location.hostname.match(domainPattern);
    return match && match.length >= 2 ? match[1] : null;
};
const subdomain = extractPartooSubdomain();

export const ENV = subdomain ?? 'prod';

export const STATIC_BASE_URL =
    ENV === 'prod' ? 'https://static.partoo.co' : `https://static.${subdomain}.partoo.co`;
export const WEBSOCKET_LIVE_UPDATES_URL =
    ENV === 'dev'
        ? 'wss://app.dev.partoo.co/websockets'
        : `wss://websockets.${subdomain}.partoo.co`;
export const FEEDBACK_MANAGEMENT_BASE_URL =
    ENV === 'prod' ? 'https://survey.partoo.co' : `https://survey.${subdomain}.partoo.co`;
