import React from 'react';

import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { DiffusionCleanerStatusFilters } from 'admin/common/api/types/diffusion_cleaner';

import NotFoundTemplate from 'admin/common/components/templates/NotFoundTemplate/NotFoundTemplate';

import { EmptyPlaceholder } from 'admin/content/logs/common/components/List.styled';
import { useDiffusionStatus } from 'admin/content/tools/pages/DiffusionCleaner/hooks/useDiffusionStatus';

import { BUSINESSES_PER_ERROR_PAGE } from './ErrorCard';
import {
    SkeletonButton,
    SkeletonLine1,
    SkeletonLine2,
    SkeletonLine2Wrapper,
} from './Skeleton.styled';

import { BusinessCard } from '../BusinessCard/BusinessCard';
import { BusinessInfo, RoundedGreyBorderWithLayout } from '../BusinessCard/BusinessCard.styled';

const Skeleton = () => {
    return (
        <RoundedGreyBorderWithLayout>
            <BusinessInfo>
                <SkeletonLine1 />
                <SkeletonLine2Wrapper>
                    <SkeletonLine2 />
                </SkeletonLine2Wrapper>
            </BusinessInfo>
            <SkeletonButton />
        </RoundedGreyBorderWithLayout>
    );
};

export const BusinessList = ({
    filters,
    businessButtons,
    className,
    businessEditSection,
}: {
    filters: DiffusionCleanerStatusFilters;
    businessButtons: string[];
    className?: string;
    businessEditSection: string;
}) => {
    const { t } = useTranslation();

    const { data, isLoading, pageHasError, refetch } = useDiffusionStatus(filters);

    const illustrationElement: IllustrationLaptopElement = {
        name: IllustrationLaptopEnum.Sad,
        type: IllustrationCategoryEnum.Laptop,
    };

    return (
        <div className={className}>
            {isLoading && (
                <>
                    {new Array(BUSINESSES_PER_ERROR_PAGE).fill(0).map((_, index) => (
                        <Skeleton key={`${filters.org}-${filters.errorKey}-${index}`} />
                    ))}
                </>
            )}
            {!pageHasError && (
                <>
                    {data?.map(business => (
                        <BusinessCard
                            key={business.id + filters.errorKey}
                            business={business}
                            buttons={businessButtons}
                            provider={filters.provider}
                            orgId={filters.org}
                            publisher={filters.publisher}
                            businessEditSection={businessEditSection}
                            callLogId={business.call_log_id}
                        />
                    ))}
                </>
            )}
            {pageHasError && (
                <EmptyPlaceholder>
                    <NotFoundTemplate
                        show
                        title={t('admin:page_diffusion_cleaner__error_placeholder__title')}
                        imgSrc={illustrationElement}
                        buttonText={t(
                            'admin:page_diffusion_cleaner__business_list_error_placeholder__button',
                        )}
                        handleClick={refetch}
                        icon={[FontAwesomeIconsPartooUsed.faRotate, IconPrefix.SOLID]}
                    />
                </EmptyPlaceholder>
            )}
        </div>
    );
};
