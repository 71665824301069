import { useMutation } from 'react-query';

import api from 'admin/common/api/calls';
import {
    CreateNewKeywordPayload,
    KeywordAdminData,
} from 'admin/common/api/types/competitiveBenchmark';

export const useCreateNewKeyword = (
    onSuccess: (keyword: KeywordAdminData) => void,
): {
    isLoading: boolean;
    mutate: (payload: CreateNewKeywordPayload) => Promise<KeywordAdminData>;
} => {
    const { mutate: createNewKeyword, isLoading } = useMutation(
        (payload: CreateNewKeywordPayload): Promise<KeywordAdminData> => {
            const { orgId, newKeyword } = payload;
            return api.competitiveBenchmark.createNewKeyword(orgId, newKeyword);
        },
        {
            onSuccess,
        },
    );

    return {
        mutate: createNewKeyword as (payload: CreateNewKeywordPayload) => Promise<KeywordAdminData>,
        isLoading,
    };
};
