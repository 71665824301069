import { KeywordAdminData } from 'admin/common/api/types/competitiveBenchmark';

export const hasAllConditionsToCreateKeyword = (
    newKeyword: string,
    keywordList: KeywordAdminData[],
) => {
    const isNotInList = !keywordList.map(k => k.keyword).includes(newKeyword);
    const isNotEmpty = newKeyword !== '';
    const isLengthBetweenBoundaries = newKeyword.length >= 2 && newKeyword.length < 128;

    const allConditions = [isNotInList, isNotEmpty, isLengthBetweenBoundaries];
    return allConditions.every(condition => condition);
};
