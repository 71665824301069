import { useEffect, useState } from 'react';

import { KeywordAdminData } from 'admin/common/api/types/competitiveBenchmark';

import { useCreateNewKeyword } from '../../hooks/useCreateNewKeyword';
import { useGetKeywordsFromOrg } from '../../hooks/useGetKeywordsFromOrg';
import { useOrgIdParsed } from '../../hooks/useOrgIdParsed';
import { useToggleKeyword } from '../../hooks/useToggleKeyword';
import { hasAllConditionsToCreateKeyword } from '../../utils/hasAllConditionsToCreateKeyword';
import { updateKeywordStatus } from '../../utils/updateKeywordStatus';
import { KeywordsSection } from '../KeywordsSection/KeywordsSection';
import { KeywordsSectionPlaceholder } from '../KeywordsSectionPlaceholder/KeywordsSectionPlaceholder';
import { ModalCreateKeyword } from '../ModalCreateKeyword/ModalCreateKeyword';

export const KeywordsSectionDisplayHandlerSection = () => {
    const orgId = useOrgIdParsed();
    const [openedKeywordModal, setOpenedKeywordModal] = useState(false);
    const [keywordList, setKeywordList] = useState<KeywordAdminData[]>([]);

    const { keywords: initialKeywords } = useGetKeywordsFromOrg(orgId);
    const { mutate: toggleKeyword } = useToggleKeyword((keyword: KeywordAdminData) =>
        setKeywordList(prev => prev.map(k => (k.keyword === keyword.keyword ? keyword : k))),
    );
    const { mutate: createNewKeyword } = useCreateNewKeyword((keyword: KeywordAdminData) => {
        updateKeywordStatus(orgId, keywordList, keyword, toggleKeyword);
        setKeywordList(prev => [...prev, keyword]);
    });

    useEffect(() => {
        setKeywordList(initialKeywords || []);
    }, [initialKeywords]);

    const onClose = () => {
        setOpenedKeywordModal(false);
    };

    const toggleModal = () => {
        setOpenedKeywordModal(prev => !prev);
    };

    const handleNewKeyword = (newKeyword: string) => {
        createNewKeyword({ orgId, newKeyword });
    };

    const handleConditionsToCreateKeyword = (newKeyword: string) => {
        return hasAllConditionsToCreateKeyword(newKeyword, keywordList);
    };

    return (
        <>
            {initialKeywords && initialKeywords.length === 0 && (
                <KeywordsSectionPlaceholder action={setOpenedKeywordModal} />
            )}
            {keywordList && keywordList.length > 0 && (
                <KeywordsSection
                    keywords={keywordList}
                    action={toggleModal}
                    switchAction={toggleKeyword}
                />
            )}
            <ModalCreateKeyword
                opened={openedKeywordModal}
                onClose={onClose}
                onConfirm={handleNewKeyword}
                conditionsToSave={handleConditionsToCreateKeyword}
            />
        </>
    );
};
