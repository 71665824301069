import { UseFormSetError } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import api from 'app/api/v2/api_calls';

import { FEEDBACK_FORM } from 'admin/common/data/queryKeysConstants';

type FeedbackFormParams = {
    org_id: number;
    external_id: string;
    email_reply_source: string;
    email_reply_subject: string;
    expiration_interval_seconds: number;
    looker_studio_dashboard_link: string;
    display_name: string;
};

const formatPayload = (payload: FeedbackFormParams) => {
    return {
        external_id: payload.external_id,
        email_reply_source: payload.email_reply_source,
        email_reply_subject: payload.email_reply_subject,
        expiration_interval_seconds: payload.expiration_interval_seconds,
        looker_studio_dashboard_link: payload.looker_studio_dashboard_link || null,
        display_name: payload.display_name,
    };
};

export const useCreateOrUpdateFeedbackForm = (setError: UseFormSetError<any>) => {
    const queryClient = useQueryClient();
    const onError = (e: any) => {
        const error = e?.response?.data?.detail?.[0];
        if (!error) {
            return;
        }

        const message = error.msg;
        const field = error.loc?.[1];

        if (!message || !field) {
            return;
        }

        setError(field, { message });
    };

    const { mutate: createFeedbackForm, isLoading: createIsLoading } = useMutation(
        (params: { payload: FeedbackFormParams }) => {
            return api.feedbackForm.createFeedbackForm({
                org_id: params.payload.org_id,
                ...formatPayload(params.payload),
            });
        },
        {
            onSuccess: ({ org_id }) => {
                queryClient.invalidateQueries([FEEDBACK_FORM, org_id]);
            },
            onError,
        },
    );

    const { mutate: updateFeedbackForm, isLoading: updateIsLoading } = useMutation(
        (params: { formId: string; payload: FeedbackFormParams }) => {
            return api.feedbackForm.updateFeedbackForm(
                params.formId,
                formatPayload(params.payload),
            );
        },
        {
            onSuccess: ({ org_id }) => {
                queryClient.invalidateQueries([FEEDBACK_FORM, org_id]);
            },
            onError,
        },
    );

    const createOrUpdate = (formId: string | undefined, payload: FeedbackFormParams) => {
        if (formId) {
            updateFeedbackForm({ formId, payload });
        } else {
            createFeedbackForm({ payload });
        }
    };

    return {
        createOrUpdateFeedbackForm: createOrUpdate,
        isCreatingOrUpdating: createIsLoading || updateIsLoading,
    };
};
