import styled from '@emotion/styled';

export const DrawerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const DrawerHeading = styled.div`
    display: block;
    flex-direction: column;
    gap: 8px;
    width: 300px;
`;

export const ResponseContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    text-overflow: ellipsis;
`;

export const UnknownErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
