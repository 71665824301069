import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { CustomConfirmModalWithText } from './CustomConfirmModalWithText';

type Props = {
    opened: boolean;
    onClose: () => void;
    onConfirm: (keyword: string) => void;
    conditionsToSave: (newKeyword: string) => boolean;
};

export const ModalCreateKeyword = ({ opened, onClose, onConfirm, conditionsToSave }: Props) => {
    const { t } = useTranslation();
    const [validationStyle, setValidationStyle] = useState<'disable' | 'error'>('disable');

    const handleOnClose = () => {
        setValidationStyle('disable');
        onClose();
    };

    const handleOnConfirm = (text: string) => {
        if (!conditionsToSave(text)) {
            setValidationStyle('error');
            return;
        }

        onConfirm(text);
        handleOnClose();
    };

    return (
        <CustomConfirmModalWithText
            validationStyle={validationStyle}
            inputTextType="text"
            opened={opened}
            onClose={handleOnClose}
            onConfirm={handleOnConfirm}
            subTitle={t('admin:competitive_benchmark__modal_create_keyword_subtitle')}
            title={t('admin:competitive_benchmark__modal_create_keyword_title')}
            errorNotice={t('admin:competitive_benchmark__modal_create_keyword_error_notice')}
            inputTextLabel={t('keyword')}
            resetNotice={() => setValidationStyle('disable')}
        />
    );
};
