import styled from '@emotion/styled';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin: 24px;
    min-width: 660px;
`;
