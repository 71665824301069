import React, { FC, useEffect, useState } from 'react';

import { Option, toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import MessagingExportAdminApiCall from 'app/api/v2/api_calls/messagingExportApiCalls';

import EmailInput from 'admin/common/components/inputs/EmailInput';
import { NewOrganizationSelect } from 'admin/common/components/inputs/OrganizationSelect/NewOrganizationSelect';
import { NewProviderSelect } from 'admin/common/components/inputs/ProviderSelect/NewProviderSelect';
import BorderedContainer from 'admin/common/components/layout/BorderedContainer';
import AdminPage from 'admin/common/components/templates/AdminPage';
import { PageFormRow } from 'admin/common/components/templates/AdminPage/AdminPage.styled';
import SubmitButton from 'admin/common/components/templates/AdminPage/SubmitButton';

import { useMe } from 'admin/common/hooks/queries/useMe';

import {
    GoogleAgentStatusChoices,
    GoogleAgentStatusSelect,
} from './components/GoogleAgentStatusSelect';

const MessagingExporter: FC = () => {
    const { t } = useTranslation();
    const [provider, setProvider] = useState<Option | undefined>(undefined);
    const [org, setOrg] = useState<Option | undefined>(undefined);
    const [email, setEmail] = useState<string>('');
    const [status, setStatus] = useState<Option | undefined>(undefined);

    const { data: user } = useMe();

    useEffect(() => {
        setEmail(user?.email ?? '');
    }, [user]);

    const onProviderChange = (value?: Option) => {
        setProvider(value);
        setOrg(undefined);
    };

    const resetForm = () => {
        setOrg(undefined);
        setProvider(undefined);
        setStatus(undefined);
    };

    const { mutate, isLoading } = useMutation(MessagingExportAdminApiCall.bulkExport, {
        onSuccess: () => {
            toast.success(
                t('admin:page_exporter__success_toast__content'),
                t('admin:page_exporter__success_toast__title'),
            );
            resetForm();
        },
        onError: (err: any) => {
            toast.error(err.message, t('admin:toast_error__title'));
            resetForm();
        },
    });

    const submitForm = async () => {
        mutate({
            email,
            org_id: org?.value,
            provider: provider?.value,
            status: status?.value as GoogleAgentStatusChoices,
        });
    };

    const isFormValid = () => {
        if (!email) {
            return false;
        }
        return !!org || !!provider;
    };

    return (
        <AdminPage
            dataTrackId="messaging_exporter__page"
            title={t('admin:page_messages_exporter__title')}
            description={t('admin:page_messages_exporter__description')}
            infoLink={'https://partoo.elium.com/tile/view/280'}
        >
            <BorderedContainer>
                <PageFormRow>
                    <NewOrganizationSelect
                        dataTrackId="admin_messages_exporter__filter_organization"
                        orgs={org}
                        setOrgs={setOrg}
                        queryParams={{
                            provider: provider?.value,
                        }}
                        placeholder={t('admin:organization')}
                    />
                </PageFormRow>
                <PageFormRow>
                    <NewProviderSelect
                        dataTrackId="admin_messages_exporter__filter_provider"
                        provider={provider}
                        setProvider={onProviderChange}
                    />
                    <GoogleAgentStatusSelect statuses={status} setStatuses={setStatus} />
                </PageFormRow>
                <PageFormRow>
                    <EmailInput
                        placeholder={t('admin:receiving_email')}
                        email={email}
                        onChange={setEmail}
                        isMandatory
                        hideExplanation
                    />
                </PageFormRow>
            </BorderedContainer>
            <SubmitButton
                dataTrackId="admin_messages_exporter__export_message_data"
                disabled={!isFormValid()}
                text={t('admin:page_exporter__button_export_data')}
                onClick={submitForm}
                loading={isLoading}
            />
        </AdminPage>
    );
};

export default MessagingExporter;
