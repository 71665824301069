import styled from '@emotion/styled';
import { greyColorObject } from '@partoohub/branding';

export const KeywordsSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: solid 1px ${greyColorObject.initial};
`;
