import {
    KeywordAdminData,
    UpdateKeywordPayload,
} from 'admin/common/api/types/competitiveBenchmark';

export const updateKeywordStatus = (
    orgId: number,
    keywordList: KeywordAdminData[],
    keyword: KeywordAdminData,
    switchAction: (payload: UpdateKeywordPayload) => void,
): void => {
    const activeKeyword = keywordList.find(k => k.active && k.keyword !== keyword.keyword);

    const keywordToToggle = keywordList.find(k => k.keyword === keyword.keyword);

    if (activeKeyword && !keywordToToggle?.active) {
        switchAction({
            orgId,
            keyword: activeKeyword,
        });
    }

    if (keywordToToggle) {
        switchAction({
            orgId,
            keyword: keywordToToggle,
        });
    }
};
