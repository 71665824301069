import { FontAwesomeIconElement } from '@partoohub/ui';
import { TFunction } from 'i18next';

interface ImportLogData {
    id: string;
    bulkType: 'business' | 'user';
    bulkFormat: 'default' | 'more_hours' | 'message_settings' | 'old_import';
    name: string;
    icon: FontAwesomeIconElement;
}

export const translateImportLogData = (t: TFunction): ImportLogData[] => {
    return [
        {
            id: 'main_information',
            bulkType: 'business',
            bulkFormat: 'default',
            name: t('admin:page_import_log__main_information'),
            icon: ['fa-store'],
        },
        {
            id: 'more_hours',
            bulkType: 'business',
            bulkFormat: 'more_hours',
            name: t('admin:page_import_log__more_hours'),
            icon: ['fa-clock'],
        },
        {
            id: 'message_settings',
            bulkType: 'business',
            bulkFormat: 'message_settings',
            name: t('admin:page_import_log__message_settings'),
            icon: ['fa-message'],
        },
        {
            id: 'users',
            bulkType: 'user',
            bulkFormat: 'default',
            name: t('admin:page_import_log__users'),
            icon: ['fa-users'],
        },
        {
            id: 'old_importer',
            bulkType: 'business',
            bulkFormat: 'old_import',
            name: t('admin:page_import_log__old_import'),
            icon: ['fa-upload'],
        },
    ];
};

export const importLogStatuses = (t: TFunction): { [key: string]: string } => {
    return {
        success: t('admin:page_import_log__status__success'),
        input_error: t('admin:page_import_log__status__input_error'),
        column_error: t('admin:page_import_log__status__column_error'),
        no_modification: t('admin:page_import_log__status__no_modification'),
    };
};
